<template>
  <section>
    <v-container fluid>
      <v-card :height="$store.state.Settings.windowSize.y - 100" class="d-flex shadow-none transparent align-center"> 
        <v-row align="center" justify="center">
          <v-col cols="12" md="6" class="pa-10">
            <div class="text-h4 font-weight-bold">{{$t('have any problem?') | capitalize}}</div>
            <div class="subtitle-1 gray5--text mt-5">{{$t('If you need instant support, use the live chat option to reach us quickly, and our support will be returned as soon as possible after you send us a message') | capitalize}}</div>
            <div class="d-flex mt-5">
              <div class="subtitle-1 font-weight-bold mt-2 mx-3">
                  {{$t('email')}}:
              </div>
              <div class="subtitle-1 success--text mt-2 mx-3">
                xxxxxxxxxxxx
              </div>
            </div>
            <div class="d-flex">
              <div class="subtitle-1 font-weight-bold mt-2 mx-3">
                  {{$t('mobile')}}:
              </div>
              <div class="subtitle-1 success--text mt-2 mx-3">
                xxxxxxxxxxxx
              </div>
            </div>
            <v-btn depressed color="secondary" :height="42" :width="150" class="rounded-lg white--text mt-5">
              <img src="@/assets/img/pos_svg/msg.png" class="mx-2" alt="">
              {{$t('start conversation')}}</v-btn>
          </v-col>
          <v-col cols="12" md="6">
            <img src="@/assets/img/pos_svg/supportSys.svg" class="d-block ma-auto"
            alt="qawaem" />
          </v-col>
        </v-row>

      </v-card>
    </v-container>
  </section>
</template>

<script>


export default {
  name: "Support",
  computed: {
  },

  components: {

  },
  created: function () {
    this.$store.state.sidebarIsOpen = false;
  },
  destroyed: function () {

  },
  data: () => ({


  }),
  watch: {

  },
  mounted() {

  },
  methods: {
    queryParams() {

    },
    pageMainData() {

    },

    getData() {

    },

  },
};
</script>


<style lang="scss" scoped>

</style>