<template>
  <v-card class="shadow rounded-lg recipeChart my-2">
    <v-row justify="center">

      <v-col cols="12" class="py-0" id="chart" :key="key">
        <apexchart type="bar" id="apexRecipeChart" :height="$store.state.Settings.windowSize.y -260" :options="chartOptions" :series="series"></apexchart>

      </v-col>
    </v-row>
  </v-card>
</template>







<script>
export default {
  name: "SalesChartPie",
  computed: {
  },
  props: {
    summary: { default: [] },
    summary_categories: { default: [] },
    quantities: { default: [] },
  },
  watch: {
    summary_categories(){

    }
  },
  data: () => ({
    key: 1,
    width: 0,

    series: [],
          chartOptions: {
            chart: {
              type: 'bar',
              height: 430,
              
            },
            plotOptions: {
              bar: {
                horizontal: true,
                dataLabels: {
                  position: 'top',
                  
                },
              }
            },
            dataLabels: {
              enabled: true,
              offsetX: -6,
              style: {
                fontSize: '12px',
                colors: ['#fff']
              },
              
            },
            colors: ["#45ade2" , "#334d6e"],
            stroke: {
              show: true,
              width: 1,
              colors: ['#fff']
            },
            tooltip: {
              shared: true,
              intersect: false
            },
            xaxis: {
              categories: [],
            },
          },



  }),
  mounted() {

    this.width =
      document.getElementById("chart").offsetWidth < 490
        ? document.getElementById("chart").offsetWidth - 50
        : document.getElementById("chart").offsetWidth - 210;
    if (this.width > 0) {
      this.chartOptions.xaxis.categories = this.summary_categories;

      this.series = [
        {
          name: this.$i18n.t('sales reports'),
          data: [...this.summary],
        },
        {
          name: this.$i18n.t('quantity'),
          data: [...this.quantities],
        },
      ];
      this.key = this.key + 1

    }


  }

};
</script>
