<template>
  <v-app v-resize="onResize">
    <SplashLoader v-if="$store.state.splashScreen == true"></SplashLoader>
    <router-view></router-view>
    <SpeedTest v-if="$store.state.splashScreen == false" />
    <v-slide-y-reverse-transition>
      <template v-if="updateExists && !$store.state.splashScreen">
        <v-snackbar bottom right :value="updateExists" :timeout="0" color="primary">
          <v-row align="center" justify="space-between">
            <v-col cols="auto" class="font-weight-bold">
              {{ $t('new update found') }}
            </v-col>
            <v-col cols="auto">
              <v-btn outlined @click="refreshApp">
                {{ $t('update now') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-snackbar>
      </template>
    </v-slide-y-reverse-transition>
  </v-app>
</template>

<style lang="scss">
@import "./scss/variables.scss";
@import "./scss/core.scss";
</style>
<script>
import SplashLoader from "@/components/SplashLoader.vue";
import SpeedTest from "@/components/SpeedTest.vue";
import notificationSound from "@/assets/notifications_sound.mp3";
import { UpdateSystem } from "@/mixins/UpdateSystem";
var sound = new Audio(notificationSound);
export default {
  name: "App",
  mixins: [UpdateSystem],
  components: { SplashLoader, SpeedTest },
  data: () => ({
    isLoading: false,
    registration: null,
    updateExists: false,
    refreshing: false,
  }),
  mounted() {
    this.$i18n.locale = localStorage.getItem('language') ? localStorage.getItem('language') : 'ar';
    this.$store.dispatch("checkRoute");
    this.$getToken().then((token) => {
      console.log('fcm token', token);
      // this.form.fcm_token = token
    });
    navigator.serviceWorker.onmessage = (event) => {
      if (event) {
        console.log(event.data?.data);
        this.$store.state.bookingCount = +event.data?.data['gcm.notification.booking_count']
        this.$store.state.notificationsCount++
      }
    };

  },
  watch: {
    $route() {
      this.$store.dispatch("checkRoute");
    },
    '$store.state.notificationsCount'() {
      if (this.$store.state.notificationsCount) {
        sound.play();
        // media.muted = false;
        // media.play();
      }
    }
  },
  created() {
    window.addEventListener('notification', this.notification);
  },
  methods: {
    notification(event) {
      console.log('event', event);
    },
    onResize() {
      this.$store.state.Settings.windowSize = { x: window.innerWidth, y: window.innerHeight }
    },
  },
};
</script>
