<template>
  <v-dialog content-class="overflow-hidden" v-model="dialog" max-width="900">

    <v-card class="backgroundW  px-5 overflow-y-auto shadow" :height="$store.state.Settings.windowSize.y - 170">
      <v-btn absolute class="mt-2" :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'" small fab
        color="grey lighten-1" text @click="dialog = false">
        <v-icon color="grey lighten-1">mdi-close</v-icon>
      </v-btn>
      <v-card-title class="d-flex justify-space-between py-2">
        {{ $t('keyboard shortcuts') | capitalize }}
      </v-card-title>
      <v-divider></v-divider>

      <v-col cols="12" v-if="dialog">
        <DynamicTable :isLoading="false" :data="hotkeys" :header="tableHeader"
          :tableHeight="$store.state.Settings.windowSize.y - 250" />
      </v-col>




    </v-card>


  </v-dialog>
</template>

<script>
import DynamicTable from '../DynamicTable.vue';

export default {
  name: "HotKeysDialog",

  computed: {

  },

  components: {
    DynamicTable
  },
  methods: {


  },
  data: () => ({
    dialog: false,
    tableHeader: [],
    hotkeys: [],
  }),
  watch: {
    '$store.state.Settings.key_clicked'() {
      switch (this.$store.state.Settings.key_clicked) {
        case 'F1':
          this.dialog = !this.dialog
          break;
        case 'F12':
          this.$router.push('/close-sift')
          break;
        default:
          break;
      }
    },

  },

  mounted() {
    this.tableHeader = [
      { text: "keyboard button", key: "key", type: 'text', classes: "" },
      { text: "usage", key: "usage", type: 'text', classes: "" },
    ];
    this.hotkeys = [
      {
        key: 'F1',
        usage: this.$i18n.t('open keyboard shortcuts')
      },
      {
        key: 'F2',
        usage: `${this.$i18n.t('export pdf')} / ${this.$i18n.t('pending request')}`
      },
      {
        key: 'F3',
        usage: `${this.$i18n.t('export excel')} / ${this.$i18n.t('new request')}`
      },
      {
        key: 'F4',
        usage: `${this.$i18n.t('select customer')}  ${this.$i18n.t('')}`
      },
      {
        key: 'F5',
        usage: this.$i18n.t('refresh data')
      },
      {
        key: 'F6',
        usage: `${this.$i18n.t('discount')}  ${this.$i18n.t('')}`
      },
      {
        key: 'F7',
        usage: `${this.$i18n.t('select agent')}  ${this.$i18n.t('')}`
      },
      {
        key: 'F8',
        usage: `${this.$i18n.t('select machine')}  ${this.$i18n.t('')}`
      },
      {
        key: 'F9',
        usage: `${this.$i18n.t('pay')}  ${this.$i18n.t('')}`
      },
      {
        key: 'F10',
        usage: this.$i18n.t('save data')
      },
      {
        key: 'F11',
        usage: this.$i18n.t('full screen')
      },
      {
        key: 'F12',
        usage: this.$i18n.t('close shift')
      },
      {
        key: 'Esc',
        usage: this.$i18n.t('cancel')
      },
    ]
  },
};
</script>

<style scoped lang="scss">

</style>