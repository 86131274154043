<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" overlay-opacity="0.75" :max-width="width">
      <v-card relative class="backgroundW pt-6 pb-3">
        <v-btn absolute class="" :style="$vuetify.rtl == true ? 'left: 5px; top: 3px' : 'right: 5px;top: 3px'" small fab
          color="grey lighten-1" text @click="closeDialog">
          <v-icon color="grey lighten-1">mdi-close</v-icon>
        </v-btn>

        <v-col cols="12" class="py-5">
          <v-row justify="space-between" align="center">
            <v-col cols="2" class="text-center">
              <div class="subtitle-1 font-weight-bold">
                {{ $t('pending requests') | capitalize }}
              </div>
            </v-col>
            <v-col cols="6" class="pa-0 d-flex justify-space-between">
              <v-text-field background-color="light" v-model="search" outlined
                @keydown.enter="(e) => getData(e.target.value)" @click:clear="(e) => getData(e.target.value)"
                @click:append="getData(search)" append-icon="mdi-magnify" :placeholder="$i18n.t('search')
                " single-line clearable hide-details solo dense :height="37"></v-text-field>
              <v-btn depressed :loading="isLoading" @click="getData()" :height="37" :width="80" color="success"
                class="rounded-lg mx-2">
                {{ $t("search") }}
              </v-btn>
            </v-col>


            <GenericInput type="date" :value="to_date" :solo="false" :maxDate="today" @input="to_date = $event"
              prefix="to date" :required="false" :hide-details="true" :keydownEnter="getData" :isLoading="isLoading"
              :dense="true" :cols="[4, 4, 3]">
            </GenericInput>

          </v-row>
        </v-col>

        <v-col cols="11" class="d-block ma-auto">
          <v-col cols="12" class="font-weight-bold d-flex align-center justify-space-between">
            <div>{{ $t('on waiting pending requests') | capitalize }}</div>
            <div class="success--text font-weight-medium" v-if="this.requests.length">{{ $t('requests') | capitalize }}
              {{ this.requests.length }}</div>
          </v-col>
          <v-simple-table dense class="shadow">
            <thead>
              <tr>
                <th class="font-weight-bold subtitle-2">{{ $t('ID') | capitalize }}</th>
                <th class="font-weight-bold subtitle-2">{{ $t('customer') | capitalize }}</th>
                <th class="font-weight-bold subtitle-2">{{ $t('mobile') | capitalize }}</th>
                <th class="font-weight-bold subtitle-2">{{ $t('value') | capitalize }}</th>
                <th class="font-weight-bold subtitle-2">{{ $t('item count') | capitalize }}</th>
                <th class="font-weight-bold subtitle-2">{{ $t('') }}</th>
              </tr>
            </thead>
            <tbody>

              <tr v-for="(row, index) in requests" :key="index" 
                class="position-relative mb-5">

                <td>{{ row.m_id || '-' }}</td>
                <td>{{ row.customer_name || '-' }}</td>
                <td>{{ row.customer_phone || $t('not available') }}</td>
                <td>{{ row.total_price_after_discount || 0 | float }}</td>
                <td>{{ row.items_count || 0 }} {{ $t('item') | capitalize }}</td>
                <td>
                  <v-btn elevation="0" color="transparent" fab @click="viewRequest(row)" x-small>
                    <v-icon color="black">mdi-eye</v-icon>
                  </v-btn>

                  <v-btn elevation="0" color="transparent" fab @click="deleteRequest(row)" x-small>
                    <v-icon color="error">mdi-close</v-icon>
                  </v-btn>
                </td>
                <div class="tdDetails">
                  <!-- <div class="subtitle-2 font-weight-regular gray5--text">
                    <span class="font-weight-bold black--text subtitle-2 mx-2">{{ $t('notes') | capitalize }}: </span>
                    {{ row.notes || $t('no notes found') | capitalize }}
                  </div> -->
                </div>
              </tr>

              <tr v-if="requests.length == 0 && !isLoading" class="white">
                <th colspan="9" class="py-3" style="border : 0">
                  <v-alert class="d-block ma-auto" dense outlined text color="success" type="info">
                    {{ $t('no pending requests added') | capitalize }}
                  </v-alert>
                </th>
              </tr>

              <tr v-if="isLoading" class="white">
                <td colspan="7" class=" py-5">
                  <AnimatedLoading  :isLoading="isLoading" />
                </td>
              </tr>
            </tbody>

          </v-simple-table>
        </v-col>
        <v-col cols="11" sm="11" class="d-block  ma-auto" v-if="requests.length > 0">
          <Pagination :pagination="pagination" :limit="rows" :showRows="false" :backValueMethod="changePage"
            :isLoading="isLoading"></Pagination>
        </v-col>


      </v-card>
    </v-dialog>



    <DeleteConfirmation v-if="deleteDialog == true" :dialog="deleteDialog" :item="null"
      :backValueMethod="deleteRequestApprove" />


  </v-row>
</template>







<script>
import GenericInput from '../GenericInput.vue';
import DeleteConfirmation from './DeleteConfirmation.vue';
import Pagination from '../Pagination.vue';
import AnimatedLoading from '../AnimatedLoading.vue';


export default {
  name: "PendingRequestModal",
  props: {
    width: { default: 1200 },
    dialog: { default: false },
    returnCloseDialog: { type: Function },
  },
  mounted() {

  },
  computed: {

  },
  data: () => ({
    deleteDialog: false,
    deleteID: null,
    isLoading: false,
    requests: [],
    to_date: null,
    search: '',
    today: null,
    pagination: null,
    page: 1,
    rows: 5,
  }),
  methods: {
    closeDialog() {
      this.returnCloseDialog();
    },

    getData() {
      this.isLoading = true;
      this.$api
        .GET_METHOD(`get_sales_orders?active=1&word=${this.search || ''}&rows=${this.rows}&page=${this.page}&to_date=${this.to_date}`)
        .then((response) => {
          this.isLoading = false;
          if (response.check) {
            this.requests = response.data.items.data;
            this.page = response.data.items.current_page;
            this.pagination = {
              page: response.data.items.current_page,
              totalPages: response.data.items.last_page,
              per_page: response.data.items.per_page,
              totalRows: response.data.items.total,
            };

          }
        });
    },
    deleteRequest(row) {
      this.deleteID = row.m_id
      this.deleteDialog = true
    },
    changePage(page, limit) {
      this.page = page;
      this.rows = limit;
      this.getData();
    },
    deleteRequestApprove(status) {
      this.deleteDialog = false;
      if (status == true) {
        this.$api.POST_METHOD(`delete_sales_order/${this.deleteID}`).then((response) => {
          if (response.check) {
            this.getData();
          }
        });
      }
    },
    viewRequest(row) {
      console.log(row);
      this.$router.push({
        name: "SalesPendingRequestShow",
        params: { pending_request: row, id: row.m_id },
      });
      this.returnCloseDialog();
    },
  },
  watch: {
    dialog() {
      if (this.dialog == true) {
        this.requests = [];
        this.today = this.$global.GetCurrentDate();
        this.to_date = this.$global.GetCurrentDate();
        if (this.isLoading == false) {
          this.getData();
          this.isLoading = true
        }
      } else {
        this.returnCloseDialog();
      }
    },
    to_date() {
      if (this.isLoading == false) {
        this.getData();
        this.isLoading = true
      }

    }
  },
  components: {
    GenericInput,
    DeleteConfirmation,
    Pagination,
    AnimatedLoading
}
};
</script>


<style scoped lang="scss">
.tdDetails {
  position: absolute;
  bottom: 12px;
  right: 18px;

  div {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    padding: 0 0rem;
    transition: .3s;
  }
}
</style>