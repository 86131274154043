<template>
   <v-dialog v-model="dialog" overlay-opacity="0.75" persistent :max-width="900">
      <v-card relative :key="modalKey" class="d-flex align-center flex-wrap" :min-height="300">

         <v-col cols="12" v-if="(!isLoading && event.booking)">

            <v-row justify="space-around">
               <v-col cols="12" sm="5" class="subtitle-1">
                  <!-- customer -->
                  <v-sheet color="white" class="pa-3 rounded-lg">
                     <v-row align="center">
                        <v-col cols="auto" class="subtitle-1 gray3--text">
                           <img :height="60" :width="60" src="@/assets/img/pos_svg/customer_avatar.svg"
                              class="d-block  mi-start-auto rounded-circle" :alt="booking_data.barber_customer_name" />
                        </v-col>
                        <v-col cols="auto" class="subtitle-1 gray3--text">
                           <div class="font-weight-bold">
                              {{ booking_data.barber_customer_name | capitalize }}
                           </div>
                           <div>
                              {{ booking_data.barber_customer_mobile }}
                           </div>
                        </v-col>

                     </v-row>

                     <v-col cols="12" class="subtitle-2 gray3--text">
                        <div>{{ $t('notes') | capitalize }}</div>
                        <div class="gray6--text px-5">
                           {{ booking_data.barber_customer_notes }}
                        </div>
                     </v-col>
                  </v-sheet>
                  <!-- barber -->
                  <v-sheet color="light" class="pa-3 rounded-lg">
                     <v-row align="center">
                        <v-col cols="12" class="caption pb-0 font-weight-bold gray3--text">
                           {{ $t('barber') | capitalize }}
                        </v-col>
                        <v-col cols="auto" class="subtitle-1 gray3--text" v-lazy-container="{ selector: 'img' }">
                           <img :height="45" :width="45" :data-src="($store.state.endpointURL + booking_data.barber_image)"
                              class="d-block  mi-start-auto rounded-circle" :alt="booking_data.barber_name" />
                        </v-col>
                        <v-col cols="auto" class="subtitle-1 gray3--text">
                           {{ booking_data.barber_name }}
                        </v-col>
                     </v-row>

                  </v-sheet>
               </v-col>
               <v-col cols="auto">
                  <v-divider vertical></v-divider>
               </v-col>
               <v-col cols="12" sm="5">
                  <v-sheet color="light" class="pa-3 rounded-lg">
                     <v-row justify="space-between">
                        <v-col cols="auto" class="subtitle-2 gray3--text">
                           {{ booking_date | dateAr }}
                        </v-col>
                        <v-col cols="auto" class="subtitle-2 gray3--text">
                           {{ event.booking.start | time }} : {{ event.booking.end | time }}
                        </v-col>
                     </v-row>
                     <v-row justify="space-between" v-for="(service, index) in services" :key="index">
                        <v-col cols="auto" class="caption black--text">
                           {{ service.name | capitalize }}

                           <v-tooltip v-if="service.description" bottom>
                              <template v-slot:activator="{ on, attrs }">
                                 <v-icon size="19" v-bind="attrs" v-on="on">mdi-information</v-icon>
                              </template>
                              <span>{{ service.description }}</span>
                           </v-tooltip>

                        </v-col>
                        <v-col cols="auto" class="caption font-weight-bold black--text">
                           {{ service.price | float }}
                        </v-col>
                        <v-col cols="12" class="py-0 px-5"
                           v-if="(services.length > index + 1)"><v-divider></v-divider></v-col>
                     </v-row>
                  </v-sheet>
                  <v-sheet outlined class="pa-3  mt-5 rounded-lg">
                     <v-row justify="space-between">
                        <v-col cols="auto" class="caption gray3--text">
                           {{ $t('total services') | capitalize }}
                        </v-col>
                        <v-col cols="auto" class="caption gray3--text">
                           {{ services.length }}
                        </v-col>
                     </v-row>
                     <v-row justify="space-between">
                        <v-col cols="auto" class="caption gray3--text">
                           {{ $t('total after vat') | capitalize }}
                        </v-col>
                        <v-col cols="auto" class="caption gray3--text">
                           {{ booking_data.total_without_vat | float }}
                        </v-col>
                     </v-row>
                     <v-row justify="space-between">
                        <v-col cols="auto" class="caption gray3--text">
                           {{ $t('total vat') | capitalize }}
                        </v-col>
                        <v-col cols="auto" class="caption gray3--text">
                           {{ booking_data.total_vat | float }}
                        </v-col>
                     </v-row>
                     <v-row justify="space-between">
                        <v-col cols="auto" class="caption font-weight-bold gray3--text">
                           {{ $t('total with vat') | capitalize }}
                        </v-col>
                        <v-col cols="auto" class="caption font-weight-bold gray3--text">
                           {{ booking_data.total_with_vat | float }}
                        </v-col>
                     </v-row>
                  </v-sheet>
               </v-col>
            </v-row>

            <v-col cols="12" class="d-flex align-center justify-space-between">
               <v-btn class="mx-2 white--text" v-if="!booking_data.has_invoice" @click="save" :loading="isSaveLoading"
                  depressed color="error" :height="37" :min-width="120">
                  {{ $t('cancel booking') }}
               </v-btn>
               <v-col cols="6" class="py-0" v-if="booking_data.has_invoice">
                  <v-alert type="success" dense outlined>
                     {{ $t('booking invoice released') | capitalize }}
                  </v-alert>
               </v-col>
               <v-btn class="mx-2" text depressed color="red4" @click="closeDialog" :height="37" :width="120">
                  {{ $t('close') }}
               </v-btn>
            </v-col>
         </v-col>

         <v-col cols="12" v-if="isLoading">
            <AnimatedLoading :height="100" :isLoading="isLoading" />
         </v-col>
      </v-card>

   </v-dialog>
</template>

<script>
import AnimatedLoading from '@/components/AnimatedLoading.vue';


export default {
   name: "ShowEventRequestDialog",
   props: {
      backValueMethod: { type: Function },
      dialog: { default: false },
      booking_date: { default: null },
      event: { default: Object }
   },
   computed: {
      totals() {
         if (this.services) {
            const price = this.services.reduce((oldValue, newValue) => {
               return Number(+oldValue) + +newValue.price || 0;
            }, 0);
            const vat = this.services.reduce((oldValue, newValue) => {
               return Number(+oldValue) + (+newValue.price * (newValue.tax_percent || 15 / 100)) || 0;
            }, 0);
            const price_with_vat = price + vat
            return {
               price: price,
               vat: vat,
               price_with_vat: price_with_vat,
            }
         }
         else {
            return {
               price: 0,
               vat: 0,
               price_with_vat: 0,
            }
         }
      }
   },
   components: { AnimatedLoading },
   watch: {
      dialog() {
         if (this.dialog) {
            this.getData();
         } else {
            this.services = [];
            this.booking_data = Object;
         }
      }
   },
   data: () => ({
      isLoading: false,
      valid: false,
      isSaveLoading: false,
      modalKey: 0,
      booking_data: Object,
      services: []
   }),
   methods: {
      closeDialog() {
         this.backValueMethod(false)
      },

      getData() {
         this.isLoading = true;
         this.$api.GET_METHOD(`barber_booking/${this.event.booking.id}`).then((response) => {
            this.isLoading = false;
            if (response.check) {
               this.services = response.data.services || [];
               this.booking_data = response.data.data || Object;
            }
         })
      },
      save() {
         this.isSaveLoading = true;
         this.$api.POST_METHOD(`cancel_barber_booking/${this.event.booking.id}`).then((response) => {
            this.isSaveLoading = false;
            if (response.check) {
               this.backValueMethod(true)
            }
         })
      },

   },
   mounted() {

   },
};
</script>
