import { render, staticRenderFns } from "./ShiftsControl.vue?vue&type=template&id=6acfaf07&scoped=true&"
import script from "./ShiftsControl.vue?vue&type=script&lang=js&"
export * from "./ShiftsControl.vue?vue&type=script&lang=js&"
import style0 from "./ShiftsControl.vue?vue&type=style&index=0&id=6acfaf07&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6acfaf07",
  null
  
)

export default component.exports