<template>
  <section :style="'height :' + ($store.state.Settings.windowSize.y - 70) + 'px'" class="overflow-hidden">
    <v-container fluid class="py-0">

      <v-row justify="space-between">
        <v-col cols="12" md="6" class="py-0">
          <v-card class="shadow mt-5 rounded-lg pa-5">
            <v-row align="center" justify="space-between">
              <v-col cols="12" md="auto">
                <v-row align="center">
                  <v-col cols="auto" class="pa-1">
                    <v-btn depressed class="rounded-sm error--text rounded-lg gray11" small height="37"
                      @click="newRequest">
                      {{ $t('new request') }}
                    </v-btn>
                  </v-col>
                  <v-col cols="auto" md="auto" class="pa-1">
                    <CustomerModal :backValueMethod="setCustomer" :isBooking="$route.params.booking_data? true : false" />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="auto" class="pa-1">
                <v-badge bordered color="accent" :content="sales_orders_count" :left="$vuetify.rtl" :right="!$vuetify.rtl"
                  overlap>
                  <v-btn class="white--text rounded-lg" small @click="pendingRequestDialog = true" height="37"
                    color="secondary" depressed>
                    {{ $t('pending requests') | capitalize }}
                  </v-btn>
                </v-badge>
                <!-- <div @click="pendingRequestDialog = true" class="subtitle-2 accent countPill">
                  {{ $t('pending requests') | capitalize }}
                  <span class="accent">{{ sales_orders_count }}</span>
                </div> -->
              </v-col>

            </v-row>

          </v-card>
          <v-card :height="$store.state.Settings.windowSize.y - 150" class="shadow rounded-lg">


            <v-col cols="12" class="mt-2 rounded-sm pa-5" sm="12">
              <v-row align="center" justify="space-between">
                <v-col cols="12" md="auto" class="pa-0">

                  <div class="subtitle-1" v-if="active_step == 1 && activeCategoryID == 0">{{ $t('main category') |
                    capitalize
                  }}</div>
                  <div class="subtitle-1 d-flex align-center cursor_pointer"
                    @click="active_step = 1; activeCategoryID = activeCategory.parent_id; activeCategoriesArray = categories"
                    v-if="active_step == 1 && activeCategoryID != 0">
                    <div class=" d-flex align-center  mx-2" v-if="activeCategoryID != 0">
                      <img src="@/assets/img/pos_svg/back arrow.svg" class="light rounded-pill pa-2" height="40"
                        alt="qawaem" />
                    </div>
                    <div>
                      {{ $t('sub category') | capitalize }}
                    </div>
                  </div>

                  <div class="subtitle-1 d-flex align-center cursor_pointer"
                    @click="active_step = 1; activeCategoryID = activeCategory.parent_id" v-if="active_step == 2">
                    <div class=" d-flex align-center  mx-2">
                      <img src="@/assets/img/pos_svg/back arrow.svg" class="light rounded-pill pa-2" height="40"
                        alt="qawaem" />
                    </div>
                    <div>
                      {{ $t('select item') | capitalize }}
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" md="6" class="pa-0">
                  <v-text-field background-color="#e9e9e9" v-model="pageData.search" class="rounded-lg"
                    @keydown.enter="(e) => getSearch(e.target.value)" @click:clear="(e) => getSearch(e.target.value)"
                    @click:append="getSearch(pageData.search)" append-icon="mdi-magnify"
                    :placeholder="$i18n.t('item name or scan items') | capitalize" single-line clearable hide-details solo
                    dense :height="37">
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>

            <!-- Categories -->
            <v-col cols="12" class="pt-2 pa-5" v-if="active_step == 1 && !pageData.isLoading">
              <CategoriesBar :categories="activeCategoriesArray" :activeCategory="activeCategoryID"
                :backValueMethod="setActiveCategory" :stock_watch="stock_watch" />
            </v-col>
            <!-- Items -->
            <v-col cols="12" class="pt-2 pa-5" v-if="active_step == 2 && !pageData.isLoading">
              <CategoryItems :items="active_category_item" :activeCategory="activeCategoryID"
                :backValueMethod="setActiveItem" :stock_watch="stock_watch"  />
              <v-col cols="12" class="d-flex pa-0 justify-center">
                <!-- prev -->
                <v-btn depressed :disabled="(itemPage == 1)" :loading="pageData.isLoading"
                  @click="changeItemPage(itemPage - 1)" class="mx-5 primary white--text"
                  :class="!$vuetify.rtl ? 'rounded-bl-xl rounded-tl-xl' : 'rounded-br-xl rounded-tr-xl'">
                  <v-icon v-if="$vuetify.rtl">mdi-arrow-right-drop-circle</v-icon>
                  <v-icon v-if="!$vuetify.rtl">mdi-arrow-left-drop-circle</v-icon>
                </v-btn>
                <!-- next -->
                <v-btn depressed @click="changeItemPage(itemPage + 1)" :loading="pageData.isLoading"
                  :disabled="(itemMaxPage == itemPage)" class="mx-5 primary white--text"
                  :class="!$vuetify.rtl ? 'rounded-br-xl rounded-tr-xl' : 'rounded-bl-xl rounded-tl-xl'">
                  <v-icon v-if="!$vuetify.rtl">mdi-arrow-right-drop-circle</v-icon>
                  <v-icon v-if="$vuetify.rtl">mdi-arrow-left-drop-circle</v-icon>
                </v-btn>
              </v-col>
            </v-col>


            <v-col cols="12" v-if="pageData.isLoading" class="mt-16">
              <AnimatedLoading classes="pt-16 my-16 pa-5" :isLoading="pageData.isLoading" />
            </v-col>

          </v-card>

        </v-col>
        <v-col cols="12" md="6" class="my-2">

          <!-- table -->
          <InvoiceData :stock_watch="stock_watch" />

          <!-- prices & payments -->
          <v-col cols="12" sm="12">
            <v-row align="center">

              <!-- prices calculations -->
              <v-col cols="12" sm="5" lg="4" class="">
                <!-- <div class="d-flex mt-1 justify-space-between">
                  <div class="caption">{{ $t('items') | capitalize }}</div>
                  <div class="caption">{{ totalPieces || 0 }} {{ $t('item') | capitalize }}</div>
                </div> -->

                <!--  -->
                <div class="d-flex mt-1 justify-space-between">
                  <div class="caption">{{ $t('without vat') | capitalize }}</div>
                  <div class="caption">
                    {{ getInvoiceStore.total_price_with_discount | float }}
                  </div>
                </div>
                <!--  -->
                <div class="d-flex mt-1 justify-space-between">
                  <div class="caption">{{ $t('vat') | capitalize }}</div>
                  <div class="caption">
                    {{ getInvoiceStore.total_vat_after_discount | float }}
                  </div>
                </div>
                <!--  -->
                <div class="d-flex mt-1 justify-space-between" v-if="discount.type == 3">
                  <div class="caption">{{ $t('coupon') | capitalize }}</div>
                  <div class="caption">
                    <span v-if="discount.coupon">{{ discount.coupon.coupon_code }}</span>
                  </div>
                </div>
                <div class="d-flex mt-1 justify-space-between" v-else>
                  <div class="caption">{{ $t('discount') | capitalize }}</div>
                  <div class="caption">
                    <span v-if="discount.value" class="error--text">
                      (-{{ totalPrice - totalPriceWithDiscount | float }})</span>
                    <span v-if="discount.type == 2 && discount.value">{{ discount.value || 0 }}%</span>
                    <span v-if="discount.value == 0">{{ 0 | float }}</span>
                  </div>
                </div>
                <!--  -->
                <div class="d-flex mt-1 justify-space-between" v-if="!discount.value">
                  <div class="caption font-weight-bold">{{ $t('with vat') | capitalize }}</div>
                  <div class="caption font-weight-bold">{{ totalPriceWithVat || 0 | float }}</div>
                </div>
                <!--  -->
                <div class="d-flex mt-1 justify-space-between" v-if="discount.value">
                  <div class="caption font-weight-bold">{{ $t('with vat after discount') | capitalize }}</div>
                  <div class="caption font-weight-bold">
                    {{ getInvoiceStore.total_price_after_discount_with_vat || 0 | float }}</div>
                </div>
              </v-col>

              <!-- payments -->
              <v-col cols="12" sm="7" lg="8" class="pa-0 mt-1">
                <v-card class="pa-3 elevation-0  rounded-lg">

                  <v-row align="center" justify="space-between">
                    <v-col cols="6" class="d-flex justify-center text-center pa-1">
                      <SalesAgentModal :sales_agents="sales_agents" :sellar="salesAgent" :backValueMethod="setAgent" />
                    </v-col>

                    <v-col cols="6" class="d-flex justify-center text-center pa-1">
                      <MachineModal :machines="network_machines" :machine="machineId" :backValueMethod="setMachine" />
                    </v-col>
                    <v-col cols="6" class="d-flex justify-center position-relative text-center pa-1 py-0">
                      <div style="width: 100%;" class="px-1" v-if="payments.length !== 0">
                        <div class="d-flex justify-space-between" v-for="(payment, index) in payments" :key="index">
                          <div class="subtitle-2" v-if="payment.payment_type == 1">
                            {{ $t('cash') | capitalize }}
                          </div>
                          <div class="subtitle-2" v-if="payment.payment_type == 2">
                            {{ $t('network') | capitalize }}
                          </div>
                          <div class="subtitle-2">{{ payment.amount | float }}</div>
                        </div>
                        <div class="d-flex justify-space-between remainingCustomer" v-if="remainingCustomer">
                          <div class="subtitle-2 remainingCustomer">
                            {{ $t('remaining to customer') | capitalize }}
                          </div>
                          <div class="subtitle-2 remainingCustomer">{{ remainingCustomer | float }}</div>
                        </div>
                      </div>
                      <div class="subtitle-2 gray7--text rounded-lg" style="width: 100%;" v-if="payments.length == 0">
                        {{ $t('no payments added') | capitalize }}
                      </div>
                    </v-col>

                    <v-col cols="6" class="d-flex justify-center text-center pa-1 py-0">
                      <PaymentsModal :backValueMethod="setPayment" :cart_type="cart_type"
                        :setCartType="(type) => { cart_type = type }" :machineID="network_machine.id" />
                    </v-col>
                  </v-row>

                </v-card>
              </v-col>
            </v-row>
          </v-col>

          <!-- submit buttons -->

          <v-row align="center" justify="space-between">
            <v-col cols="12" md="6" class="d-flex align-center">
              <BarberModal v-if="barbers.length" :barbers="barbers" :barber="barber" :backValueMethod="setBarber" />
              <v-btn color="secondary" outlined depressed :min-width="120" @click="discountDialog = true"
                :x-large="$vuetify.breakpoint.name == 'lg' || $vuetify.breakpoint.name == 'xl'" class="mx-1 rounded-lg">
                <v-icon size="18" class="d-none d-lg-block" left>mdi-sale</v-icon>
                <span v-if="!discount.value">{{ $t('discount') }} {{ $t('?') }}</span>
                <span v-if="discount.value">
                  {{ $t('edit discount') }}
                </span>
              </v-btn>
              <v-btn color="orange5" depressed :loading="requestLoading" :min-width="120"
                :x-large="$vuetify.breakpoint.name == 'lg' || $vuetify.breakpoint.name == 'xl'"
                v-if="!$route.params.pending_request"
                :disabled="!(getInvoiceStore.invoice.length > 0 && customer_data.id)" @click="makeRequest(2)"
                class="mx-1 white--text rounded-lg">
                {{ $t('pending this request') }}
              </v-btn>
            </v-col>
            <v-col cols="12" md="6" class="">
              <v-btn color="success" depressed :loading="requestLoading" :min-width="120"
                :x-large="$vuetify.breakpoint.name == 'lg' || $vuetify.breakpoint.name == 'xl'"
                :disabled="!(requestValid)" @click="makeRequest(1)" class="d-block mi-start-auto rounded-lg">
                {{ $t('apply request') }}
              </v-btn>
            </v-col>

          </v-row>

        </v-col>
      </v-row>

      <CustomModal :width="400" :dialog="requestStatusDialog" :buttonTwoMethod="() => requestStatusDialog = false"
        @close="requestStatusDialog = $event" :dialogContent="{
          title: requestStatus == 1 ? $i18n.t('request done successfully') : $i18n.t('request is pending now'),
          img: requestStatus == 1 ? 'check.svg' : 'pending_request.svg',
          details: null,
          buttonOneTitle: null,
          buttonOneColor: 'red12',
          buttonOneClass: 'red8--text',
          buttonTwoTitle: null,
          buttonTwoColor: 'white',
          buttonTwoLoading: false,
          buttonTwoClass: 'primary--text',
        }" />
      <DiscountModal :dialog="discountDialog" :discount="discount" :coupons="coupons" :setDiscount="setDiscount"
        :returnCloseDialog="() => discountDialog = false" />

      <PendingRequestModal :dialog="pendingRequestDialog" :returnCloseDialog="() => pendingRequestDialog = false" />
    </v-container>

  </section>
</template>

<script>
import CategoriesBar from '@/components/CategoriesBar.vue';
import CategoryItems from '@/components/CategoryItems.vue';
import InvoiceData from "@/components/InvoiceData";
import CustomerModal from '@/components/modals/CustomerModal.vue';
import { createNamespacedHelpers } from "vuex";
import CustomModal from '@/components/modals/CustomModal.vue';
import DiscountModal from '@/components/modals/DiscountModal.vue';
import PaymentsModal from '@/components/modals/PaymentsModal.vue';
import PendingRequestModal from '@/components/modals/PendingRequestModal.vue';
import AnimatedLoading from '@/components/AnimatedLoading.vue';
import SalesAgentModal from '@/components/modals/SalesAgentModal.vue';
import BarberModal from '@/components/modals/BarberModal.vue';
import MachineModal from '@/components/modals/MachineModal.vue';
// import Pagination from '@/components/Pagination.vue';
const { mapState, mapActions, mapGetters } = createNamespacedHelpers("InvoiceStore");

export default {
  name: "SalesControl",
  computed: {
    ...mapState(["totalPriceWithVat", "totalPrice", "description", "totalPieces", "discount", "customer_data",]),
    ...mapGetters(["totalPriceWithDiscount", "getInvoiceStore"]),
    remainingCustomer(){
      let remaining = +this.totalPaid.toFixed(2) - +this.getInvoiceStore.total_price_after_discount_with_vat.toFixed(2) 
      return +this.getInvoiceStore.total_price_after_discount_with_vat ? remaining < 1 ? 0 : Math.abs(remaining)  : 0
    },
    requestValid() {
      if (+this.getInvoiceStore.total_price_after_discount_with_vat.toFixed(2) <= +this.totalPaid.toFixed(2) && this.remainingCustomer == 0 && this.getInvoiceStore.invoice.length !== 0 && this.customer_data.id) {
        return true;
      } else {
        return false
      }
    },

  },
  components: {
    CategoriesBar,
    CategoryItems,
    InvoiceData,
    CustomerModal,
    CustomModal,
    DiscountModal,
    PaymentsModal,
    PendingRequestModal,
    AnimatedLoading,
    SalesAgentModal,
    BarberModal,
    MachineModal,
    // Pagination
  },
  created: function () {
    this.$store.state.sidebarIsOpen = false;
  },
  destroyed: function () {
    this.$store.state.sidebarIsOpen = true;
    this.clearInvoice().finally(() => { });
  },
  data: () => ({
    // Keys
    invoiceKey: 1,
    sales_orders_count: 0,
    activeCategoryID: 0,
    activeCategory: null,
    discountDialog: false,
    pendingRequestDialog: false,
    network_machine: {
      id: 0,
      name: '-',
    },
    sales_agent: {},
    cart_type: 3,
    payments: [],
    coupons: [],
    totalPaid: 0,
    requestStatusDialog: false,
    requestStatus: 1,
    activeItem: null,
    active_step: 1,
    customer_id: null,
    requestLoading: false,
    salesAgent: {},
    machineId: null,
    barber: {
      id: 0,
      name: null,
    },
    barbers: [],
    // lockups
    stock_watch: 0,
    allCategoriesArray: [],
    activeCategoriesArray: [],
    categories: [],
    sub_categories: [],
    network_machines: [],
    sales_agents: [],
    items: [],
    active_category_item: [],
    itemPage: 1,
    itemMaxPage: 1,
    sales_invoice_type_id: 2,
    // page data
    pageData: {
      screen_code: "01-001",
      url: null,
      controlRoute: null,
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      isTrashed: 1,
      rowsNumber: 10,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
      controlDialog: false,
      controlID: null,
      queryParam: '?'
    },
  }),
  watch: {
    requestStatusDialog() {
      if (this.requestStatusDialog == true) {
        setTimeout(() => {
          this.requestStatusDialog = false;
          this.requestLoading = false;
          this.resetInvoice()
        }, 2000);
      }
    },
    '$store.state.Settings.key_clicked'() {
      switch (this.$store.state.Settings.key_clicked) {
        case 'F2':
          this.pendingRequestDialog = !this.pendingRequestDialog
          break;
        case 'F3':
          this.resetInvoice();
          break;
        case 'F5':
          this.getData();
          break;
        case 'F6':
          this.discountDialog = !this.discountDialog;
          break;
        case 'F10':
          if (this.requestValid) {
            this.requestLoading = true;
            this.saveInvoice();
          }
          break;
        default:
          break;
      }
    },
    $route() {
      this.pageData.queryParam = '?';
      this.active_step = 1;
      this.activeCategory = null;
      this.activeCategoryID = null;
      this.resetInvoice();
      this.queryParams();
      this.pageMainData();
      this.getData();
      if (this.$route.params.pending_request) {
        this.getPendingRequest(this.$route.params.pending_request)
      }
      if (this.$route.params.booking_data) {
        this.getBookingData(this.$route.params.booking_data)
      }
    },
    discount() {
      this.setInvoice(this.getInvoiceStore.invoice)
    },

  },
  mounted() {
    if (this.$global.CheckAction('06-008', 2)) {
      this.resetInvoice();
      this.queryParams();
      this.pageMainData();
      this.getData();
      if (this.$route.params.pending_request) {
        this.getPendingRequest(this.$route.params.pending_request)
      }
      if (this.$route.params.booking_data) {
        this.getBookingData(this.$route.params.booking_data)
      }
    } else {
      this.$router.push("/");
      this.$global.ShowAlert('info', this.$i18n.t('no permission'), false, this.$i18n.t('check permission with admin'))
    }
  },
  methods: {
    ...mapActions(['clearInvoice', 'resetInvoice', 'setInvoice', 'changeCustomer', 'setDiscount', 'setTaxesArray']),
    queryParams() {

    },
    pageMainData() {

    },

    getData() {
      this.pageData.isLoading = true;
      this.activeCategory = null;
      this.activeCategoriesArray = [];
      this.categories = [];
      this.items = [];
      this.sub_categories = [];
      this.allCategoriesArray = [];
      this.barbers = [];
      this.itemPage = 1;
      this.itemMaxPage = 1;
      this.$api.GET_METHOD(`create_sales_invoice`).then((response) => {
        this.pageData.isLoading = false;
        if (response.check) {
          this.stock_watch = response.data.stock_watch || 0;
          this.activeCategoriesArray = response.data.categorys || [];
          this.allCategoriesArray.push(...response.data.categorys, ...response.data.sub_categorys);
          this.categories = response.data.categorys || [];
          this.sub_categories = response.data.sub_categorys || [];
          response.data.items.forEach(item => {
            switch (item.default_report_unit_type) {
              case 1:
                item.unit_id = item.small_unit_id;
                break;
              case 2:
                item.unit_id = item.mid_unit_id;
                break;
              case 3:
                item.unit_id = item.big_unit_id;
                break;

              default:
                item.unit_id = 0
                break;
            }
            var newItem = {
              id: item.id,
              item_id: item.id,
              name: item.name,
              item_name: item.name,
              item_image: item.image,
              category_id: item.category_id,
              image: item.image,
              category_name: item.category_name,
              price: item.sale_price,
              price_with_vat: item.price_with_vat,
              unit_id: item.unit_id,
              quantity: 1,
              tax_percent_id: item.tax_id,
              tax_percentage: item.tax_percent,
              tax_percent: item.tax_percent,
              price_include_vat: item.price_include_vat,
              item_number: item.item_number,
              location: item.location,
              stock: item.stock,

            };
            this.items.push(newItem)
          });
          this.sales_orders_count = response.data.sales_orders_count || 0;
          this.sales_agents = response.data.sales_agents || [];
          this.barbers = response.data.barbers || [];
          this.coupons = response.data.coupons || [];
          this.network_machines = response.data.network_machines || [];
          this.setTaxesArray(response.data.taxes || [])

          if (response.data.sales_agents.length > 0) {
            this.salesAgent = response.data.sales_agents[0]
          }
          if (response.data.default_customer) {
            this.customer_id = response.data.default_customer.id;
            this.changeCustomer(response.data.default_customer).finally(() => { })
          }
          if (response.data.network_machines.length > 0) {
            this.machineId = response.data.network_machines[0]
            this.network_machine = response.data.network_machines[0];
          }
        }
      });
    },
    getPendingRequest(m_id) {
      this.pageData.isLoading = true;
      this.itemPage = 1;
      this.itemMaxPage = 1;
      this.$api
        .GET_METHOD(`get_sales_order/${m_id.m_id}`)
        .then((response) => {
          this.pageData.isLoading = false;
          if (response.check) {
            response.data.details.forEach(item => {
              item.tax_percentage = item.tax_percent;
              // item.tax_id = item.tax_percent_id;
              item.item_price = item.price;
            });
            this.setInvoice(response.data.details);
            var customer = {
              id: response.data.master.customer_id,
              name: response.data.master.customer_name,
              mobile: response.data.master.customer_phone,
            }
            var pending_discount = {
              type: response.data.master.discount_type,
              value: response.data.master.discount,
              discount_amount: 0,
              percent_value: 0,
            };
            this.setDiscount(pending_discount)
            this.changeCustomer(customer)
          }
        });
    },
    getBookingData(booking) {
      this.pageData.isLoading = true;
      this.itemPage = 1;
      this.itemMaxPage = 1;
      this.$api
        .GET_METHOD(`get_barber_booking/${booking.id}`)
        .then((response) => {
          this.pageData.isLoading = false;
          if (response.check) {
            this.barbers = response.data.barbers || [];
            this.barber = {
              id: response.data.data.barber_id,
              name: response.data.data.barber_name,
            };
            response.data.services.forEach(item => {
              item.tax_percentage = item.tax_percent || 15;
              item.tax_percent = item.tax_percent || 15;
              item.price = item.item_price;
              item.quantity = item.quantity || 1;
            });
            this.setInvoice(response.data.services);
            var customer = {
              id: response.data.data.barber_customer_id,
              name: response.data.data.barber_customer_name,
              mobile: response.data.data.barber_customer_mobile,
            }
            var pending_discount = {
              type: response.data.data.discount_type || 1,
              value: response.data.data.discount || 0,
              discount_amount: 0,
              percent_value: 0,
            };
            this.setDiscount(pending_discount)
            this.changeCustomer(customer)
          }
        });
    },
    getSearch(word) {
      if (word) {
        this.active_step = 2;
        this.pageData.isLoading = true;
        this.$api.GET_METHOD(`search_item?word=${word}`).then((response) => {
          this.pageData.isLoading = false;
          this.itemPage = 1;
          this.itemMaxPage = 1;
          this.active_category_item = [];
          if (response.check) {
            if (response.data.items.length > 0) {
              response.data.items.forEach(item => {
                switch (item.default_report_unit_type) {
                  case 1:
                    item.unit_id = item.small_unit_id;
                    break;
                  case 2:
                    item.unit_id = item.mid_unit_id;
                    break;
                  case 3:
                    item.unit_id = item.big_unit_id;
                    break;

                  default:
                    item.unit_id = 0
                    break;
                }
                var newItem = {
                  id: item.id,
                  item_id: item.id,
                  name: item.name,
                  item_name: item.name,
                  item_image: item.image,
                  category_id: item.category_id,
                  image: item.image,
                  category_name: item.category_name,
                  price: item.sale_price,
                  price_with_vat: item.price_with_vat,
                  unit_id: item.unit_id,
                  quantity: 1,
                  tax_percent_id: item.tax_id,
                  tax_percentage: item.tax_percent,
                  tax_percent: item.tax_percent,
                  price_include_vat: item.price_include_vat,
                  item_number: item.item_number,
                  location: item.location,
                  stock: item.stock,
                };
                this.active_category_item.push(newItem)
              });
            } else {
              this.$global.ShowAlert('info', this.$i18n.t('no search results'), false, this.$i18n.t(''))
            }
          }
        });
      } else {
        this.active_step = 1;
      }
    },
    setActiveCategory(category) {
      if (category) {
        const getCategoryIndex = this.allCategoriesArray.findIndex((cat) => cat.id == category);
        let selectedCat = this.allCategoriesArray[getCategoryIndex]
        this.itemPage = 1;
        this.itemMaxPage = 1;
        this.active_category_item = [];
        if (selectedCat.category_type == 0) {
          this.activeCategoryID = category;
          this.activeCategory = selectedCat;
          this.active_step = 2;
          this.getItemsByCategory()
        } else {
          this.activeCategoriesArray = this.$global.FilterArrayOfObject(this.allCategoriesArray, 'parent_id', selectedCat.id);
          this.activeCategoryID = selectedCat.id;
          this.activeCategory = selectedCat;
          this.active_step = 1;
        }
      }

    },
    getItemsByCategory() {
      let all_active_category_item = this.$global.FilterArrayOfObject(this.items, 'category_id', this.activeCategoryID) || [];
      const rows_per_page = 21;
      const items_length = all_active_category_item.length
      this.itemMaxPage = this.$global.CalculateNumberOfPages(items_length, rows_per_page) || 1;
      this.active_category_item = this.$global.PaginateArray(all_active_category_item, rows_per_page, this.itemPage)
    },

    setActiveItem(item) {
      this.activeItem = item;
    },
    setCustomer(customer) {
      this.customer_id = customer;
    },
    setAgent(returnedValue) {
      this.salesAgent = returnedValue;
    },
    setBarber(returnedValue) {
      this.barber = returnedValue;
    },
    setMachine(returnedValue) {
      this.machineId = returnedValue;
    },
    makeRequest(status) {
      this.requestLoading = true;
      this.requestStatus = status;
      if (status == 1) {
        this.saveInvoice();
      } else {
        this.addPendingInvoice();
      }
    },
    setPayment(returnedValue, invoice_type) {
      this.payments = returnedValue;
      console.log('invoice_type', invoice_type, returnedValue);
      switch (invoice_type) {
        case "payCash":
          this.sales_invoice_type_id = 2
          break;

        case "payBank":
          this.sales_invoice_type_id = 3
          break;
        case "payCashBank":
          this.sales_invoice_type_id = 4
          break;
        default:
          this.sales_invoice_type_id = 'ssss'
          break;
      }

      this.totalPaid = this.payments.reduce((oldValue, newValue) => {
        return Number(+oldValue) + Number(+newValue.amount);
      }, 0);
    },
    addPendingInvoice() {
      let requestBody = {
        sales_agent_id: this.salesAgent.id,
        machine_id: this.network_machine.id,
        customer_id: this.customer_data.id,
        items: this.getInvoiceStore.invoice,
        invoice_discount: this.discount.value ? 1 : 0,
        discount_type: this.discount.type || 0,
        discount: this.discount.value || 0,
        payments: this.payments,
      }
      this.$api.POST_METHOD("add_sales_order", requestBody, false).then((response) => {
        this.requestLoading = false;
        if (response.check) {
          this.activeCategory = null;
          this.activeCategoryID = null;
          this.active_step = 1;
          this.requestStatusDialog = true;
          this.resetInvoice();
          this.getData();
          const src = `src="${this.$api.serverUrl}export_sales_order/${response.data}?type=image&clientID=${this.$store.state.clientID}"`;
          this.$api.PrintInvoice(src, response.data)
        }
      })


    },
    saveInvoice() {
      if (!this.barber.id && this.barbers.length) {
        this.requestLoading = false;
        this.$swal.fire({
          title: this.$i18n.t("please check"),
          text: this.$i18n.t("choose barber"),
          icon: "info",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        let requestBody = {
          sales_invoice_type_id: this.sales_invoice_type_id,
          sales_agent_id: this.salesAgent.id,
          machine_id: this.machineId.id,
          customer_id: this.customer_data.id,
          items: this.getInvoiceStore.invoice,
          invoice_discount: this.discount.value !== 3 ? 1 : 0,
          discount_type: this.discount.type !== 3 ? this.discount.type || 1 : 0,
          discount: this.discount.value !== 3 ? this.discount.value || 0 : 0,
          is_coupon: this.discount.is_coupon || 0,
          coupon_id: this.discount.coupon_id || 0,
          payments: this.payments,
          cart_type: this.cart_type,
          description: this.getInvoiceStore.description,
          sales_order_m_id: this.$route.params.pending_request ? this.$route.params.pending_request.m_id : 0, // tell backend that it coming from pending requests
          barber_booking_id: this.$route.params.booking_data ? this.$route.params.booking_data.id : 0,// tell backend that it coming from barber booking
          barber_id: this.barber.id || 0,
        }
        this.$api.POST_METHOD("add_sales_invoice", requestBody, false).then((response) => {
          this.requestLoading = false;
          if (response.check) {
            this.sales_invoice_type_id = null;
            this.activeCategory = null;
            this.activeCategoryID = null;
            this.active_step = 1;
            this.cart_type = 3;
            this.requestStatusDialog = true;
            this.payments = [];
            this.resetInvoice();
            this.getData();
            const src = `${this.$api.serverUrl}print_sales_invoice/${response.data}?type=print&clientID=${this.$store.state.clientID}`;
            this.$api.PrintInvoiceURL(src, response.data);
            if (this.$route.params.pending_request) {
              this.$router.push('/requests-list');
            }
            if (this.$route.params.booking_data) {
              this.$router.push('/barber-booking');
            }
            this.barber = {
              id: 0,
              name: null,
            }
          }
        })
      }
    },
    changeItemPage(page) {
      if (page > 0) {
        this.itemPage = page;
        this.getItemsByCategory()
      }
    },
    newRequest() {
      if (this.$router.currentRoute.fullPath !== "/sales") {
        this.active_step = 1;
        this.barber = {
          id: 0,
          name: null,
        }
        this.$router.push("/sales");
      } else {
        this.barber = {
          id: 0,
          name: null,
        }
        this.resetInvoice()
      }
    }
  },
};
</script>


<style lang="scss" scoped>
.remainingCustomer {
  animation: color-change 1s infinite;
}

@keyframes color-change {
  0% { color:var(--error) }
  50% { color: #000}
  100% { color:var(--error) }
}
.countPill {
  min-width: 160px;
  text-align: end;
  padding: 8px 10px;
  position: relative;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  border-top-right-radius: 25px !important;
  border-bottom-right-radius: 25px !important;

  span {
    width: 41px;
    height: 41px;
    position: absolute;
    text-align: center;
    top: -1px;
    left: -22px;
    border: 2px solid #fff !important;
    border-radius: 26px !important;
    color: #fff;
    padding: 7px;
  }


}
</style>

