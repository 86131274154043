<template>
   <div style="position :fixed; bottom: 0; background: rgba(0,0,0,0.1);"
      :style="$vuetify.rtl == false? 'right : 0':'left : 0'"  class="d-flex pa-1 px-2">
      <v-icon id="wifiIcon3" size="14" color="success">mdi-signal-cellular-3</v-icon>
      <v-icon id="wifiIcon2" size="14" color="warning">mdi-signal-cellular-2</v-icon>
      <v-icon id="wifiIcon1" size="14" color="error">mdi-signal-cellular-1</v-icon>
      <v-icon id="wifiIcon0" size="14"  color="error">mdi-signal-off</v-icon>
   </div>
</template>


<script>

export default {
name: "SpeedTest",
components: {},
mounted() {
   this.networkStatus()
   document.getElementById('wifiIcon0').style.display = "block";
   document.getElementById('wifiIcon1').style.display = "none";
   document.getElementById('wifiIcon2').style.display = "none";
   document.getElementById('wifiIcon3').style.display = "none";
   setInterval(() => {
      // console.log(navigator.connection.effectiveType);

      // if (navigator.connection.effectiveType == '4g') {
      //    document.getElementById('wifiIcon1').style.display = "none";
      //    document.getElementById('wifiIcon2').style.display = "none";
      //    document.getElementById('wifiIcon3').style.display = "block";
      // }
      // if (navigator.connection.effectiveType == '3g') {
      //    document.getElementById('wifiIcon1').style.display = "none";
      //    document.getElementById('wifiIcon2').style.display = "block";
      //    document.getElementById('wifiIcon3').style.display = "none";
      // }
      // if (navigator.connection.effectiveType == '2g') {
      //    document.getElementById('wifiIcon1').style.display = "block";
      //    document.getElementById('wifiIcon2').style.display = "none";
      //    document.getElementById('wifiIcon3').style.display = "none";
      // }

      this.networkStatus()
   }, 10000);
},
data() {
   return {
      loading: false,
   }
},
methods: {
   networkStatus() {
      var arrTimes = [];
      var i = 0; // start
      var timesToTest = 5;
      var tThreshold = 150; //ms
      var testImage = "http://www.google.com/images/phd/px.gif"; // small image in your server
      var dummyImage = new Image();
      testLatency(function (avg) {
         // console.log(avg);
         if (avg <= tThreshold) {
            document.getElementById('wifiIcon0').style.display = "none";
            document.getElementById('wifiIcon1').style.display = "none";
            document.getElementById('wifiIcon2').style.display = "none";
            document.getElementById('wifiIcon3').style.display = "block";
         }
         if (avg > tThreshold && avg <= 700) {
            document.getElementById('wifiIcon0').style.display = "none";
            document.getElementById('wifiIcon1').style.display = "none";
            document.getElementById('wifiIcon2').style.display = "block";
            document.getElementById('wifiIcon3').style.display = "none";
         }
         if (avg > 700) {
            document.getElementById('wifiIcon0').style.display = "none";
            document.getElementById('wifiIcon1').style.display = "block";
            document.getElementById('wifiIcon2').style.display = "none";
            document.getElementById('wifiIcon3').style.display = "none";
         }
         if (avg == 0) {
            document.getElementById('wifiIcon0').style.display = "block";
            document.getElementById('wifiIcon1').style.display = "none";
            document.getElementById('wifiIcon2').style.display = "none";
            document.getElementById('wifiIcon3').style.display = "none";
         }
      });

      /** test and average time took to download image from server, called recursively timesToTest times */
      function testLatency(cb) {
         var tStart = new Date().getTime();
         if (i < timesToTest - 1) {
            dummyImage.src = testImage + '?t=' + tStart;
            dummyImage.onload = function () {
               var tEnd = new Date().getTime();
               var tTimeTook = tEnd - tStart;
               arrTimes[i] = tTimeTook;
               testLatency(cb);
               i++;
            };
         } else {
            /** calculate average of array items then callback */
            var sum = arrTimes.reduce(function (a, b) { return a + b; });
            var avg = sum / arrTimes.length;
            cb(avg);
         }
      }
   }
},


}
</script>
<style scoped>

</style>