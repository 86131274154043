import Vue from "vue";
import Vuex from "vuex";
import Settings from "./Settings";
import InvoiceStore from "./InvoiceStore";
import router from "../router";
import i18n from "../plugins/i18n";
import axios from "axios";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // APP Settings
    app_version: process.env.VUE_APP_VERSION, //last app update Year.Month.Day.UploadNumber 
    splashScreen: false,
    sidebarIsOpen: true,
    // endpointURL: "https://api.qawaemerp.com/", // Live
    endpointURL: "https://qawaem-api.cs-testing.com/", // Testing
    apiAccess:
      "xcVBybDQhtDRqPxFUkpe1JVsZqyGGBR1v1KqendQ2qcLbqXB9hgqin41uRJ3qGTwD0L1hmRS29a0gXbKf8EBVcBy7ofvhKviDzJ88u8p6Hs9mNncXZSXOxeSQ7Ka7tlEhCnBMFtVSgZbuYf9sBubou5pWBxu1okFMC2ksCfk3jxdTQmeHq5xBlOL3pmkqq32L26LX1EB",
    //route settings
    route: null,
    pageTitle: null,
    notificationsCount : 0,
    bookingCount : 0,
    firebaseNotifications: {},
    activeScreenCode: null,
    prevScreen: {
      screen_code: null,
      permission: null,
      main_id: null,
      main_title: null,
      cat_id: null,
      cat_title: null,
      sub_id: null,
      sub_title: null,
      routeName: null,
      url: null,
      params: {
        page: 1,
        rows: 10,
        isTrashed: 1,
        search: "",
      },
      queryParams: {
        page: 1,
        rows: 10,
        active: 1,
        word: "",
      },
      requestParam: `?`,
    },
    activeScreen: {
      screen_code: null,
      permission: null,
      main_id: null,
      main_title: null,
      cat_id: null,
      cat_title: null,
      sub_id: null,
      sub_title: null,
      routeName: null,
      url: null,
      params: {
        page: 1,
        rows: 10,
        isTrashed: 1,
        search: "",
      },
      queryParams: {
        page: 1,
        rows: 10,
        active: 1,
        word: "",
      },
      requestParam: `?`,
    },
    entityName: null,
    entityRoute: null,
    controlRoute: null,
    //  user data
    clientID: localStorage.getItem("clientID")
      ? localStorage.getItem("clientID")
      : null,
    lastUpdate: localStorage.getItem("lastUpdate")
      ? localStorage.getItem("lastUpdate")
      : " ",
    accessToken: null,
    isAuth: false,
    screens: [],
    permissions: [],
    shift: {},
    branches: [],
    current_branch: null,
    titles: [],
    current_title: null,
    current_year: null,
    years: [],
    shiftOpen: false,
    userData: {},
  },
  mutations: {
    SET_ROUTE(state, route) {
      state.route = route;
      state.activeScreenCode = route.meta.screen_code;
      state.entityName = route.meta.entity_name || null;
      state.entityRoute = route.meta.entity_path || null;
      state.controlRoute = route.meta.control_route || null;
      state.pageTitle = route.meta.title || null;
    },
    UPDATE_ACTIVE_SCREEN(state, activeScreen) {
      state.activeScreen = activeScreen;
      state.pageTitle = activeScreen.sub_title || null;
    },
    UPDATE_CLIENT(state, id) {
      state.clientID = id;
      axios.defaults.headers.common["clientID"] = id;
    },
    UPDATE_TOKEN(state, token) {
      state.accessToken = token;
      axios.defaults.headers.common["Authorization"] =  "Bearer " + token;
    },
  },

  actions: {
    checkAppVersion() {
      localStorage.setItem("app_version", process.env.VUE_APP_VERSION);
      // let localStorageVersion = localStorage.getItem("app_version");
      // if (state.app_version != localStorageVersion) {
      //   state.isAuth = false;
      //   router.push("/login");
      //   axios.interceptors.response.use(
      //     (resp) => {
      //       if(resp.config.method == 'get'){
      //         localStorage.setItem("app_version", state.app_version);
      //         // window.location.reload()
      //       }
      //       return Promise.resolve(resp)
      //     },
      //   )
      // }
    },
    checkAuth({ state }) {
      state.isAuth = localStorage.getItem(
        Vue.prototype.$global.CRYPT("cloud", "access-token")
      )
        ? true
        : false;
      if (!state.isAuth) {
        router.push("/login").catch(() => {});
      } else {
        state.accessToken = Vue.prototype.$global.DECRYPT(
          "cloud",
          localStorage.getItem(
            Vue.prototype.$global.CRYPT("cloud", "access-token")
          )
        );
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + state.accessToken;
        state.permissions = JSON.parse(
          Vue.prototype.$global.DECRYPT(
            "cloud",
            localStorage.getItem(
              Vue.prototype.$global.CRYPT("cloud", "permissions")
            )
          )
        );
        state.screens = JSON.parse(
          Vue.prototype.$global.DECRYPT(
            "cloud",
            localStorage.getItem(
              Vue.prototype.$global.CRYPT("cloud", "screens")
            )
          )
        );
      }
    },
    getUserData({ state }) {
      state.splashScreen = true;

      Vue.prototype.$api.GET_METHOD(`get_my_profile`).then((response) => {
        state.splashScreen = false;
        if (response.check) {
          if(response.data.user.shift !== null){
            Vue.prototype.$api.SET_AUTH(response.data);
          }
          else{
            Vue.prototype.$api.ShowModal('info','',i18n.t("please start shift"));
            router.push("/login");
          }
          
        }
      });
    },
    setPagesRows({ state }, data) {
      state.activeScreen.pageNumber = data.pageNumber;
      state.activeScreen.rowsNumber = data.rowsNumber;
    },
    checkRoute({ state, commit }) {
      if (state.activeScreenCode && state.isAuth) {
        state.prevScreen = {...state.activeScreen};
        var activeScreen = state.permissions.filter(
          (obj) => obj["screen_code"] === state.activeScreenCode
        )[0];
        console.log("router.currentRoute", router.currentRoute);
        activeScreen.params = router.currentRoute.params;
        activeScreen.requestParam = "?";
        if (Object.keys(router.currentRoute.query).length !== 0) {
          activeScreen.queryParams = router.currentRoute.query;
          Object.keys(router.currentRoute.query).forEach((param) => {
            activeScreen.requestParam =
              activeScreen.requestParam == "?"
                ? activeScreen.requestParam +
                  `${param}=${router.currentRoute.query[param]}`
                : activeScreen.requestParam +
                  `&${param}=${router.currentRoute.query[param]}`;
          });
        } else {
          activeScreen.queryParams = {
            page: 1,
            rows: 10,
            active: 1,
            word: "",
          };
          activeScreen.requestParam = `?active=1&rows=10&page=1&word=`;
        }

        activeScreen.routeName = router.currentRoute.name;
        console.log("prevScreen", state.prevScreen);
        console.log("activeScreen", activeScreen);
        document.title = i18n.t("Qawaem - ") + activeScreen.sub_title;
        return commit("UPDATE_ACTIVE_SCREEN", activeScreen);
      } else {
        document.title = state.pageTitle
          ? i18n.t("Qawaem - ") + i18n.t(state.pageTitle)
          : i18n.t("Qawaem - POS");
        var undefinedScreen = {
          screen_code: null,
          permission: null,
          main_id: null,
          main_title: null,
          cat_id: null,
          cat_title: null,
          sub_id: null,
          sub_title: null,
          routeName: null,
          url: router.currentRoute.fullPath,
          params: router.currentRoute.params,
          requestParam: `?`,
          queryParams: {
            page: 1,
            rows: 10,
            active: 1,
            word: "",
          },
        };
        if (Object.keys(router.currentRoute.query).length !== 0) {
          undefinedScreen.queryParams = router.currentRoute.query;
          Object.keys(router.currentRoute.query).forEach((param) => {
            undefinedScreen.requestParam =
              undefinedScreen.requestParam == "?"
                ? undefinedScreen.requestParam +
                  `${param}=${router.currentRoute.query[param]}`
                : undefinedScreen.requestParam +
                  `&${param}=${router.currentRoute.query[param]}`;
          });
        } else {
          undefinedScreen.queryParams = {
            page: 1,
            rows: 10,
            active: 1,
            word: "",
          };
          undefinedScreen.requestParam = `?active=1&rows=10&page=1&word=`;
        }
        console.log("activeScreen", undefinedScreen);
        return commit("UPDATE_ACTIVE_SCREEN", undefinedScreen);
      }
    },
  },

  modules: { Settings, InvoiceStore },
});
