<template>
  <section>
    <v-container fluid>

      <v-row align="center" justify="space-between">
        <v-col cols="12" class="py-2">
          <v-card class="pa-3 shadow rounded-lg">
            <v-row align="center" justify="space-between">
              <v-col cols="auto" class="d-flex">
                <div class=" d-flex align-center  mx-2">
                  <img src="@/assets/img/pos_svg/back arrow.svg" class="light rounded-pill pa-2" height="40"
                    alt="qawaem" />
                </div>
                <div class="font-weight-bold">
                  <div class="text-h6">{{ $t('add') }}</div>
                  <div class="subtitle-2">{{ $global.FilterPermissions('06-010').sub_title | capitalize }}</div>
                </div>
              </v-col>

              <v-col cols="12" md="7">
                <v-text-field background-color="#f4f5f7" v-model="pageData.search" outlined
                  @keydown.enter="(e) => getSearch(e.target.value)" @click:clear="(e) => getSearch(e.target.value)"
                  @click:append="getSearch(pageData.search)" append-icon="mdi-magnify" :label="
                    pageData.isTrashed == 0 ? $i18n.t('Search in deleted') : $i18n.t('search')
                  " single-line clearable hide-details dense :height="37"></v-text-field>
              </v-col>
              <v-col cols="12" md="auto">
                <v-btn block depressed :loading="pageData.isLoading" @click="getData()" :height="37" :width="100"
                  color="blue4" outlined class="rounded-lg">
                  {{ $t("search") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>

      </v-row>


      <!-- request -->

      <v-row justify="space-between" class="mb-3" v-if="master && !pageData.isLoading" :key="invoiceKey">
        <v-col cols="12" md="8" lg="8" class="py-0">
          <v-row align="center">
            <v-col cols="12" md="6" class="pa-6">
              <div class="font-weight-bold"> {{ $t('Request') }} #{{ master.m_id }}</div>
            </v-col>
            <v-col cols="12" md="6" class="pa-6 d-none">
              <v-btn depressed @click="addItemDialog = true" :height="40" :width="120" color="secondary"
                class="rounded-lg d-block mi-start-auto">
                {{ $t("add item") }}
              </v-btn>
            </v-col>
          </v-row>
          <v-card class="shadow pa-5 rounded-lg overflow-hidden">

            <v-row align="center" justify="space-between">
              <v-col cols="12" sm="6" class="d-flex justify-space-between px-3 caption py-1">
                <div class="font-weight-medium mx-3"> {{ $t('request date') | capitalize }} :</div>
                <div class="text-end mx-3 gray8--text">{{ master.sales_invoice_date }}</div>
              </v-col>
              <v-col cols="12" sm="6" class="d-flex justify-space-between px-3 caption py-1">
                <div class="font-weight-medium mx-3"> {{ $t('customer name') | capitalize }} :</div>
                <div class="mx-3 gray8--text">{{ master.customer_name | capitalize }}</div>
              </v-col>
              <v-col cols="12" sm="6" class="d-flex justify-space-between px-3 caption py-1">
                <div class="font-weight-medium mx-3"> {{ $t('invoice type') | capitalize }} :</div>
                <div class="mx-3 gray8--text">{{ master.type_title | capitalize }}</div>
              </v-col>
              <v-col cols="12" sm="6" class="d-flex justify-space-between px-3 caption py-1">
                <div class="font-weight-medium mx-3"> {{ $t('mobile') | capitalize }} :</div>
                <div class="mx-3 gray8--text">{{ master.customer_phone || '-' }}</div>
              </v-col>
              <v-col cols="12" sm="6" class="d-flex justify-space-between px-3 caption py-1">
                <div class="font-weight-medium mx-3"> {{ $t('employee name') | capitalize }} :</div>
                <div class="mx-3 gray8--text">{{ master.sales_agent_name | capitalize }}</div>
              </v-col>
              <v-col cols="12" class="px-0">
                <NoticeDataTable :debit="true" :credit="false" :canEditPrice="false" :items="pageData.rows"
                  :checkboxMethod="checkboxMethod" :discount="{
                    type: master.discount_type,
                    value: master.discount,
                    discount_amount: 0,
                    percent_value: 0,
                  }" />
              </v-col>


            </v-row>
          </v-card>

        </v-col>
        <!-- summery -->
        <v-col cols="12" md="4" lg="4" class="py-0" v-if="master">
          <v-card class="shadow pa-5 rounded-lg overflow-hidden">
            <v-row align="center" justify="space-between">
              <v-col cols="12" sm="7" class="pb-2 subtitle-2">
                <div class=""> {{ $t('items') }}</div>
              </v-col>
              <v-col cols="12" sm="5" class="pb-2 subtitle-2">
                <div class="text-end"> {{ totalsCalculations.difference_quantity || 0 }}</div>
              </v-col>

              <v-col cols="12" sm="7" class="pb-2 subtitle-2">
                <div class=""> {{ $t('without vat') }}</div>
              </v-col>
              <v-col cols="12" sm="5" class="pb-2 subtitle-2">
                <div class="text-end"> {{ totalsCalculations.difference_total_without_vat | float }}</div>
              </v-col>
              <v-col cols="12" sm="7" class="pb-2 subtitle-2">
                <div class=""> {{ $t('vat') }}</div>
              </v-col>
              <v-col cols="12" sm="5" class="pb-2 subtitle-2">
                <div class="text-end"> {{ totalsCalculations.difference_total_vat | float }} </div>
              </v-col>
              <v-col cols="12" sm="7" class="pb-2 subtitle-2">
                <div class=""> {{ $t('price with vat') }}</div>
              </v-col>
              <v-col cols="12" sm="5" class="pb-2 subtitle-2">
                <div class="text-end"> {{ totalsCalculations.difference_total_with_vat | float }}</div>
              </v-col>
            </v-row>
          </v-card>
          <v-card class="shadow pa-3 elevation-0 mt-3 rounded-lg">

            <div class="d-flex align-center justify-space-between">
              <SalesAgentModal :sales_agents="sales_agents" :sellar="salesAgent" :backValueMethod="setAgent" />
            </div>

            <div class="d-flex align-center justify-space-between"
              v-if="master.sales_invoice_type_id == 3 || master.sales_invoice_type_id == 4">
              <MachineModal :machines="network_machines" :machine="networkMachine" :backValueMethod="setMachine" />
            </div>

            <div class="d-flex align-center justify-space-between"
              v-if="master.sales_invoice_type_id == 2 || master.sales_invoice_type_id == 4">
              <TreasuryModal :treasuries="treasuries" :treasury="treasury" :backValueMethod="setTreasury" />
            </div>
            <div class="d-flex align-center justify-space-between">
              <div class="subtitle-2 font-weight-bold success--text">
                {{ totalPaid ? $t('paid in process') + ' ' + totalPaid.toFixed(2) : $t('select payment type') |
                  capitalize }}
              </div>
              <PaymentMethodNotice :type="null" :backValueMethod="setPayment"
                :sales_type_id="master.sales_invoice_type_id"
                :totalPriceWithDiscount="totalsCalculations.difference_total_with_vat" :machineID="networkMachine.id">
              </PaymentMethodNotice>
            </div>

            <v-col cols="12" class="px-0">

              <v-btn depressed @click="saveRefund" :disabled="!(saveValidation)" :height="40" :width="120"
                :loading="refundIsLoading" color="success" class="rounded-lg d-block mi-start-auto">
                {{ $t("save") }}
              </v-btn>
            </v-col>

          </v-card>
        </v-col>

      </v-row>

      <v-col cols="12" v-if="pageData.isLoading">
        <AnimatedLoading classes="pt-2 my-15 pa-5" :isLoading="pageData.isLoading" />
      </v-col>

      <v-dialog v-model="addItemDialog" overlay-opacity="0.75" persistent :min-height="$store.state.Settings.windowSize.y"
        :max-width="800">
        <v-card relative class="pa-5 pt-10">
          <v-btn absolute class="mt-n7 mx-5" :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'" small fab
            color="grey lighten-1" text @click="addItemDialog = false">
            <v-icon color="red8">mdi-close</v-icon>
          </v-btn>
          <v-card class="py-2  transparent elevation-0">
            <v-sheet :height="$store.state.Settings.windowSize.y - 200">
              <ItemsControl :categories="categories" :sub_categories="sub_categories" :key="invoiceKey"
                :allCategoriesArray="allCategoriesArray" :activeCategoriesArray="activeCategoriesArray"
                :backItemMethod="backItemMethod" />
            </v-sheet>
          </v-card>
        </v-card>
      </v-dialog>
    </v-container>
  </section>
</template>

<script>

import NoticeDataTable from '@/components/NoticeDataTable.vue';
import SalesAgentModal from '@/components/modals/SalesAgentModal.vue';
import MachineModal from '@/components/modals/MachineModal.vue';
import TreasuryModal from '@/components/modals/TreasuryModal.vue';
import PaymentMethodNotice from '@/components/modals/PaymentMethodNotice.vue';
import ItemsControl from '@/components/ItemsControl.vue';
import AnimatedLoading from '@/components/AnimatedLoading.vue';



export default {
  name: "DebtorNoticeEntity",
  components: {
    NoticeDataTable,
    SalesAgentModal,
    MachineModal,
    PaymentMethodNotice,
    ItemsControl,
    AnimatedLoading,
    TreasuryModal
  },

  data: () => ({
    pageData: {
      screen_code: "06-010",
      url: null,
      controlRoute: null,
      entityName: null,
      main: null,
      category: null,
      isLoading: false,
      isTrashed: 1,
      rowsNumber: 10,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
      controlDialog: false,
      controlID: null,
      queryParam: '?'
    },
    sales_agents: [],
    network_machines: [],
    salesAgent: Object,
    networkMachine: Object,
    treasury: Object,
    invoiceKey: 1,
    master: null,
    totalPaid: 0,
    categories: [],
    treasuries: [],
    sub_categories: [],
    allCategoriesArray: [],
    activeCategoriesArray: [],
    payments: [],
    selectedRow: [],
    payment_type: 1,
    refund_value: 0,
    addItemDialog: false,
    isDetailsLoading: false,
    refundIsLoading: false,
    refundDialog: false,
  }),
  created: function () {
    this.$store.state.sidebarIsOpen = false;
  },
  computed: {
    saveValidation() {
      if (this.master) {
        if (this.master.sales_invoice_type_id === 4) {
          if ((this.totalPaid.toFixed(2) >= this.totalsCalculations.difference_total_with_vat.toFixed(2)) && this.totalPaid > 0) {
            return true
          } else {
            return false
          }
        }
        else {
          return this.totalsCalculations.difference_total_with_vat ? true : false
        }
      } else {
        return this.totalsCalculations.difference_total_with_vat ? true : false
      }
    },
    totalsCalculations() {
      if (this.pageData.rows.length > 0) {
        // current
        const current_quantity = this.pageData.rows.reduce((oldValue, newValue) => {
          return +oldValue + +newValue.current_quantity || 0;
        }, 0);
        const current_total_without_vat = this.pageData.rows.reduce((oldValue, newValue) => {
          return +oldValue + +newValue.current_total_without_vat || 0;
        }, 0);
        const current_total_vat = this.pageData.rows.reduce((oldValue, newValue) => {
          return +oldValue + +newValue.current_total_vat || 0;
        }, 0);
        const current_total_with_vat = this.pageData.rows.reduce((oldValue, newValue) => {
          return +oldValue + +newValue.current_total_with_vat || 0;
        }, 0);
        // new
        const new_quantity = this.pageData.rows.reduce((oldValue, newValue) => {
          return +oldValue + +newValue.new_quantity || 0;
        }, 0);
        const new_total_without_vat = this.pageData.rows.reduce((oldValue, newValue) => {
          return +oldValue + +newValue.new_total_without_vat || 0;
        }, 0);
        const new_total_vat = this.pageData.rows.reduce((oldValue, newValue) => {
          return +oldValue + +newValue.new_total_vat || 0;
        }, 0);
        const new_total_with_vat = this.pageData.rows.reduce((oldValue, newValue) => {
          return +oldValue + +newValue.new_total_with_vat || 0;
        }, 0);

        // difference
        const difference_quantity = +Math.abs(current_quantity - new_quantity).toFixed(2);
        const difference_total_without_vat = +Math.abs(current_total_without_vat - new_total_without_vat).toFixed(2);
        const difference_total_vat = +Math.abs(current_total_vat - new_total_vat).toFixed(2);
        const difference_total_with_vat = +Math.abs(current_total_with_vat - new_total_with_vat).toFixed(2);

        return {
          // current
          current_quantity: current_quantity,
          current_total_without_vat: current_total_without_vat,
          current_total_vat: current_total_vat,
          current_total_with_vat: current_total_with_vat,
          // new
          new_quantity: new_quantity,
          new_total_without_vat: new_total_without_vat,
          new_total_vat: new_total_vat,
          new_total_with_vat: new_total_with_vat,
          // difference
          difference_quantity: difference_quantity,
          difference_total_without_vat: difference_total_without_vat,
          difference_total_vat: difference_total_vat,
          difference_total_with_vat: difference_total_with_vat,
        };
      }
      else {
        return {
          // current
          current_quantity: 0,
          current_total_without_vat: 0,
          current_total_vat: 0,
          current_total_with_vat: 0,
          // new
          new_quantity: 0,
          new_total_without_vat: 0,
          new_total_vat: 0,
          new_total_with_vat: 0,
          // difference
          difference_quantity: 0,
          difference_total_without_vat: 0,
          difference_total_vat: 0,
          difference_total_with_vat: 0,
        };
      }
    },
  },
  watch: {
    '$store.state.Settings.key_clicked'() {
      switch (this.$store.state.Settings.key_clicked) {
        case 'F5':
          this.getData();
          break;
        default:
          break;
      }
    },
  },
  mounted() {
    if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
      this.pageMainData();
    } else {
      this.$router.push("/");
      this.$global.ShowAlert('info', this.$i18n.t('no permission'), false, this.$i18n.t('check permission with admin'))
    }
  },
  methods: {
    pageMainData() {

    },
    getSearch(word) {
      this.pageData.search = word;
      if (this.pageData.search) {
        this.getData();
      }
    },
    getData() {
      this.selectedRow = [];
      this.pageData.rows = [];
      this.activeCategoriesArray = [];
      this.categories = [];
      this.sub_categories = [];
      this.allCategoriesArray = [];
      this.treasury_id = null
      this.invoiceKey = this.invoiceKey + 1;
      if (this.pageData.search) {
        this.pageData.isLoading = true;
        this.$api
          .GET_METHOD(`get_sales_invoice/${this.pageData.search}`)
          .then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              if (response.data.master) {
                this.pageData.rows = []
                response.data.details.forEach(item => {

                  // if (item.current_quantity !== 0) {
                  item.refund_item = false;
                  item.new_quantity = item.current_quantity;
                  item.new_price = item.current_price;
                  item.new_total_vat = item.current_total_vat;
                  item.new_total_without_vat = item.current_total_without_vat;
                  item.new_total_with_vat = item.current_total_with_vat;
                  item.difference_quantity = 0;
                  item.difference_total_without_vat = 0;
                  item.difference_total_vat = 0;
                  item.difference_total_with_vat = 0;
                  this.pageData.rows.push(item)
                  // }

                });
                // this.pageData.rows = response.data.details;
                this.master = response.data.master;

                this.sales_agents = response.data.sales_agents || [];


                this.activeCategoriesArray = response.data.categorys || [];
                this.treasuries = response.data.treasurys || [];
                this.allCategoriesArray.push(...response.data.categorys, ...response.data.sub_categorys);
                this.categories = response.data.categorys || [];
                this.sub_categories = response.data.sub_categorys || [];
                this.network_machines = response.data.network_machines || [];
                if (response.data.sales_agents.length > 0) {
                  this.salesAgent = response.data.sales_agents[0]
                }
                if (response.data.network_machines.length > 0) {
                  this.networkMachine = response.data.network_machines[0]
                }
                if (response.data.treasurys.length > 0) {
                  this.treasury = response.data.treasurys[0]
                }
              } else {
                this.$global.ShowAlert('info', this.$i18n.t('sorry'), false, this.$i18n.t('no invoice found with this number'))
              }
            }
          });
      } else {
        this.$global.ShowAlert('warning', this.$i18n.t('watch out'), false, this.$i18n.t('invoice number is mandatory'))
      }
    },
    checkboxMethod(rows) {
      this.selectedRow = rows;
    },
    saveRefund() {
      this.refundIsLoading = true;
      var refundItems = [];
      this.selectedRow.forEach(item => {
        if (item.new_quantity !== item.current_quantity) {
          refundItems.push({
            d_id: item.d_id,
            item_id: item.item_id,
            unit_id: item.unit_id,
            new_quantity: item.new_quantity,
            new_total_vat: item.new_total_vat,
            new_total_without_vat: item.new_total_without_vat,
            new_total_with_vat: item.new_total_with_vat,
          });
        }
      });
      if (this.master.sales_invoice_type_id !== 1 && !this.payments.length) {
        if (this.master.sales_invoice_type_id == 2) {
          this.payments = [
            {
              machine_id: 0,
              amount: this.totalsCalculations.difference_total_with_vat,
              payment_type: 1,
              payment_text: this.$i18n.t('cash'),
            }
          ]
        }
        if (this.master.sales_invoice_type_id == 3) {
          this.payments = [
            {
              machine_id: this.networkMachine.id,
              amount: this.totalsCalculations.difference_total_with_vat,
              payment_type: 2,
              payment_text: this.$i18n.t('bank'),
            }
          ]
        }
      }
      const requestBody = {
        sales_invoice_m_id: this.master.m_id,
        sales_invoice_type_id: this.master.sales_invoice_type_id,
        payments: this.payments,
        sales_agent_id: this.salesAgent.id,
        machine_id: this.networkMachine.id,
        treasury_id: this.treasury.id,
        items: refundItems,
      }
      this.$api.POST_METHOD("add_notice_debtor", requestBody).then((response) => {
        this.refundIsLoading = false;
        if (response.check) {
          const src = `${this.$api.serverUrl}export_notice_debtor/${response.data}?type=print&clientID=${this.$store.state.clientID}`;
          this.$api.PrintInvoiceURL(src, response.data)

          this.refundDialog = false;
          this.refund_value = 0;
          this.master = null;
          this.selectedRow = [];
          this.payments = [];
          this.totalPaid = 0;
          this.pageData.rows = [];
        }
      })
    },
    setAgent(returnedValue) {
      this.salesAgent = returnedValue;
    },
    setMachine(returnedValue) {
      this.networkMachine = returnedValue;
    },
    setTreasury(returnedValue) {
      this.treasury = returnedValue;
    },
    backItemMethod(newItem) {
      newItem.d_id = null;
      newItem.notice_type = 'new_item';
      newItem.new_quantity = newItem.quantity;
      newItem.tax_percent = newItem.tax_percentage;
      newItem.current_quantity = newItem.quantity;

      newItem.refund_item = false;
      newItem.new_quantity = newItem.quantity;
      newItem.new_price = newItem.price;
      newItem.total_new_price = newItem.price;

      newItem.old_quantity = (newItem.quantity);
      newItem.item_vat = newItem.price * (newItem.tax_percent / 100);
      newItem.total_new_price_without_vat = (newItem.price || newItem.price) * (newItem.current_quantity);
      newItem.total_new_vat = newItem.total_new_price_without_vat * (newItem.tax_percent / 100);
      newItem.total_new_price_with_vat = newItem.total_new_price_without_vat + newItem.total_new_vat;

      this.pageData.rows.push({ ...newItem });
      this.selectedRow.push({ ...newItem });
      this.addItemDialog = false
      // this.invoiceKey = this.invoiceKey + 1;
    },
    setPayment(returnedValue) {
      this.payments = returnedValue;
      this.totalPaid = this.payments.reduce((oldValue, newValue) => {
        return Number(+oldValue) + Number(+newValue.amount);
      }, 0);
      if (this.selectedRow.length && this.saveValidation) {
        this.saveRefund()
      }
    },
  },

};
</script>
