<template>
   <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="false" :preview-modal="true"
      :filename="report.user_full_name" :pdf-quality="2" :manual-pagination="true" pdf-format="a4"
      pdf-orientation="portrait" ref="html2Pdf" >
      <section slot="pdf-content" :dir="$vuetify.rtl ? 'rtl' : 'ltr'">

         <PrintPDF :header="[]" :maxPDFRows="20" :data="[{}]" :header_slot="false" :content_slot="true"
            :footer_slot="false" :showGroupHeader="false" :groupHeader="false" :footerData="[]"
            :customize_header_footer="true" :printStaticData="printStaticData">
            <template v-slot:pdf_content>

               <v-col id="print_title">
                  {{ `${$t('workday report')}` }}
               </v-col>

               <table width="100%" style="margin-top:10px" class="mt-5">
                  <tr>
                     <td width="15%" style="border-bottom:2px solid #eee !important; " class="pa-2  gray6--text">
                        {{ $t('start date') | capitalize }}
                     </td>

                     <td width="10%" style="border-bottom:2px solid #eee !important; " class="pa-2   gray6--text">
                        :
                     </td>

                     <td width="25%" style="border-bottom:2px solid #eee !important;  text-align:start !important"
                        class="pa-2 ">
                        {{ report.start_date | capitalize }}
                     </td>


                     <td width="15%" style="border-bottom:2px solid #eee !important; " class="pa-2   gray6--text">
                        {{ $t('end date') | capitalize }}
                     </td>

                     <td width="10%" style="border-bottom:2px solid #eee !important; " class="pa-2   gray6--text">
                        :
                     </td>
                     <td width="25%" style="border-bottom:2px solid #eee !important;  text-align:start !important"
                        class="pa-2 ">
                        {{ report.end_date | capitalize }}
                     </td>
                  </tr>
               </table>
               <table width="100%" style="margin-top:10px" class="mt-5">
                  <tr>
                     <td width="15%" style="border-bottom:2px solid #eee !important; " class="pa-2  gray6--text">
                        {{ $t('branch') | capitalize }}
                     </td>

                     <td width="10%" style="border-bottom:2px solid #eee !important; " class="pa-2   gray6--text">
                        :
                     </td>

                     <td width="25%" style="border-bottom:2px solid #eee !important;  text-align:start !important"
                        class="pa-2 ">
                        {{ report.branch_name | capitalize }}
                     </td>


                     <td width="15%" style="border-bottom:2px solid #eee !important; " class="pa-2   gray6--text">
                        {{ $t('employee') | capitalize }}
                     </td>

                     <td width="10%" style="border-bottom:2px solid #eee !important; " class="pa-2   gray6--text">
                        :
                     </td>
                     <td width="25%" style="border-bottom:2px solid #eee !important;  text-align:start !important"
                        class="pa-2 ">
                        {{ report.user_full_name | capitalize }}
                     </td>
                  </tr>
               </table>

               <v-row class="mt-5 align-center" style="margin-top : 30px">
                  <v-col cols="5" style="padding: 15px  margin-top : 10px">
                     <v-card class="backgroundW shadow pa-5 py-6">
                        <div class="summary_row d-flex justify-space-between">
                           <div>{{ $t("total sales") }}</div>
                           <div>{{ report.total_sales || 0 | float }}</div>
                        </div>
                        <div class="summary_row d-flex justify-space-between">
                           <div>{{ $t("sales cash") }}</div>
                           <div>{{ report.total_sales_cash || 0 | float }}</div>
                        </div>
                        <div class="summary_row d-flex justify-space-between">
                           <div>{{ $t("master card") }}</div>
                           <div>{{ report.total_sales_master || 0 | float }}</div>
                        </div>
                        <div class="summary_row d-flex justify-space-between">
                           <div>{{ $t("visa") }}</div>
                           <div>{{ report.total_sales_visa || 0 | float }}</div>
                        </div>
                        <div class="summary_row d-flex justify-space-between">
                           <div>{{ $t("mada") }}</div>
                           <div>{{ report.total_sales_mada || 0 | float }}</div>
                        </div>
                        <!-- <div class="summary_row d-flex justify-space-between">
                   <div>{{ $t("transition") }}</div>
                   <div>{{ report.total_sales_trans  || 0 | float }}</div>
                </div> -->
                        <div class="summary_row d-flex justify-space-between">
                           <div>{{ $t("other") }}</div>
                           <div>{{ report.total_sales_other || 0 | float }}</div>
                        </div>
                        <div class="summary_row d-flex justify-space-between">
                           <div>{{ $t("notice debtor") }}</div>
                           <div>{{ report.total_nd || 0 | float }}</div>
                        </div>
                        <div class="summary_row d-flex justify-space-between">
                           <div>{{ $t("notice creditor") }}</div>
                           <div>{{ report.total_nc || 0 | float }}</div>
                        </div>
                        <div class="summary_row d-flex justify-space-between">
                           <div>{{ $t("expenses") }}</div>
                           <div>{{ report.total_expenses || 0 | float }}</div>
                        </div>
                        <div class="summary_row d-flex justify-space-between">
                           <div>{{ $t("difference cash") }}</div>
                           <div>{{ report.diff_cash || 0 | float }}</div>
                        </div>
                        <div class="summary_row d-flex justify-space-between">
                           <div>{{ $t("difference network") }}</div>
                           <div>{{ calculatedTotals.total_network || 0 | float }}</div>
                        </div>
                     </v-card>
                  </v-col>
                  <v-col cols="7" style="padding: 15px; margin-top : 10px">
                     <v-card class="backgroundW shadow pa-5">
                        <table class="mini_table_two">
                           <tr>
                              <th>{{ $t("") }}</th>
                              <th>{{ $t("start") }}</th>
                              <th>{{ $t("Transactions") }}</th>
                              <th>{{ $t("end") }}</th>
                              <th>{{ $t("difference") }}</th>
                           </tr>
                           <tr>
                              <td>{{ $t('cash') }}</td>
                              <td>{{ report.start_cash || 0 | float }}</td>
                              <td>{{ report.total_cash || 0 | float }}</td>
                              <td>{{ report.end_cash || 0 | float }}</td>
                              <td>{{ report.diff_cash || 0 | float }}</td>
                           </tr>
                           <tr>
                              <td>{{ $t('visa') }}</td>
                              <td>{{ 0 | float }}</td>
                              <td>{{ report.total_sales_visa || 0 | float }}</td>
                              <td>{{ report.end_visa || 0 | float }}</td>
                              <td>{{ report.diff_visa || 0 | float }}</td>
                           </tr>
                           <tr>
                              <td>{{ $t('master card') }}</td>
                              <td>{{ 0 | float }}</td>
                              <td>{{ report.total_sales_master || 0 | float }}</td>
                              <td>{{ report.end_master || 0 | float }}</td>
                              <td>{{ report.diff_master || 0 | float }}</td>
                           </tr>
                           <tr>
                              <td>{{ $t('mada') }}</td>
                              <td>{{ 0 | float }}</td>
                              <td>{{ report.total_sales_mada || 0 | float }}</td>
                              <td>{{ report.end_mada || 0 | float }}</td>
                              <td>{{ report.diff_mada || 0 | float }}</td>
                           </tr>
                           <tr>
                              <td>{{ $t('other') }}</td>
                              <td>{{ 0 | float }}</td>
                              <td>{{ report.total_sales_other || 0 | float }}</td>
                              <td>{{ report.end_other || 0 | float }}</td>
                              <td>{{ report.diff_other || 0 | float }}</td>
                           </tr>
                           <tr>
                              <td>{{ $t('total') }}</td>
                              <td>{{ calculatedTotals.total_start | float }}</td>
                              <td>{{ calculatedTotals.total_actions || 0 | float }}</td>
                              <td>{{ calculatedTotals.total_end || 0 | float }}</td>
                              <td>{{ calculatedTotals.total_difference || 0 | float }}</td>
                           </tr>
                        </table>
                     </v-card>
                  </v-col>


               </v-row>



            </template>
         </PrintPDF>

      </section>
   </vue-html2pdf>
</template>

<script>
import PrintPDF from "@/components/PrintPDF.vue";
import VueHtml2pdf from 'vue-html2pdf'
export default {
   name: "ShiftSummery",
   computed: {
      calculatedTotals() {
         if (this.report) {
            const total_start = this.report.start_cash
            const total_actions = this.report.total_sales_master + this.report.total_sales_visa + this.report.total_sales_mada + this.report.total_sales_other
            const total_end = this.report.end_cash + this.report.end_visa + this.report.end_master + this.report.end_mada + this.report.end_other
            const total_difference = this.report.diff_cash + this.report.diff_visa + this.report.diff_master + this.report.diff_mada + this.report.diff_other
            const total_network = this.report.diff_visa + this.report.diff_master + this.report.diff_mada + this.report.diff_other
            return {
               total_start: total_start,
               total_actions: total_actions,
               total_end: total_end,
               total_difference: total_difference,
               total_network: total_network,
            }
         } else {
            return {
               total_start: 0,
               total_actions: 0,
               total_end: 0,
               total_difference: 0,
               total_network: 0,
            }
         }
      }
   },
   watch: {

   },
   mounted() {
      if (this.$route.params.report && this.$route.params.printStaticData) {
         this.report = this.$route.params.report;
         this.printStaticData = this.$route.params.printStaticData;
         this.$refs.html2Pdf.generatePdf();
      } else {
         this.$router.push('/login')
      }
   },
   components: {
      PrintPDF,
      VueHtml2pdf
   },
   data() {
      return {
         report: Object,
         printStaticData: Object,
      }
   },
   methods: {
   },
};
</script>

<style lang="scss" scoped>
.summary_row {
   padding: 0.4rem 0.2rem;
   border-bottom: 2px solid #eee;
   font-size: 12px !important;
   display: flex !important;
   justify-content: space-between !important;
}

td {
   padding: 1.2rem 0.4rem;
   font-size: 12px !important;
}

td:nth-of-type(1),
td:nth-of-type(4) {
   color: var(--gray7) !important;
}

#print_title {
   width: fit-content;
   text-align: center;
   font-size: 12px !important;
   color: var(--blue1);
   background: var(--blue12);
   display: block;
   padding: 1.2rem;
   margin: 30px auto;
}

.mini_table_two {
   width: 100%;
   border-collapse: collapse !important;

   tr {
      border: 1px solid #f7f9fb !important;

      th {
         text-transform: capitalize;

      }

      td,
      th {
         border: 1px solid #eee !important;
         background: #fff !important;
         height: 34.5px !important;
         font-size: 12px !important;
         padding: 12px;

      }

      td {
         background-color: #f5f6fa;
      }

      &:nth-of-type(odd) {

         td,
         th {
            background: #f7f9fb !important;
         }
      }

      // &:last-of-type {
      //
      //    td,
      //    th {
      //       font-weight: bold;
      //    }
      // }
   }
}
</style>