<template>
  <v-container style="height: 100vh" class="d-flex align-center justify-center bgError">
    <div>
      <v-row align="center" justify="center">
        <v-col cols="12">
          <img src="@/assets/img/pos_svg/error404.svg" alt="qawaem" />
        </v-col>
        <v-col cols="12" class="text-center">
          <h1>{{ $t('page not found') |capitalize}}</h1>
          <!-- <p class="text--disabled my-3">
            {{ $t("the page you are looking for doesn't exist or an other error occurred") |capitalize }}.

              {{ $t("go back, or head over to home page to choose a new direction")  |capitalize}}.
          </p> -->
          <v-btn
            color="primary"
            @click="$router.go(-1)"
            class="mt-10" outlined
            elevation="0"
          >{{ $t('back previous page') |capitalize}}</v-btn>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<style scoped lang="scss">
.bgError {
 // background-image: url("../../assets/img/svg/errorBg.svg");
 // background-attachment: inherit;
 // background-repeat: no-repeat;
 // background-position: 50%;
 // background-size: cover;
  img {
    margin: auto;
    display: block;
    max-height: 300px;
  }
}
</style>

<script>
export default {
  name: "Error404",
  computed: {},
};
</script>
