<template>
  <div id="appBarMenu" v-if="$store.state.userData.user.branch_data">
    <div class="d-flex align-center">
      <!-- <img src="@/assets/img/svg_icons/git-branch.svg" class="mx-2" height="25" alt="qawaem"> -->

      <div class="caption">
        <small>
          <span class="font-weight-bold">
            {{ $store.state.userData.user.branch_data.vat_register_name || ' '  | capitalize}}
          </span>
        </small>
        <div> ( <span class="secondary--text  font-weight-bold ">
            {{ $store.state.clientID || ' ' }}</span> )
          <span class="gray5--text">
            {{ $t('tax number')  | capitalize}} : {{ $store.state.userData.user.branch_data.vat_number || ' ' }}</span>
        </div>

      </div>
    </div>
  </div>
</template>
<style scoped>

</style>

<script>
export default {
  name: "CompanyDetailMenu",
  computed: {},
  data: () => ({

  }),
  mounted() {

  },

  methods: {

  },
};
</script>
