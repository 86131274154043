// import axios from "axios";
import Vue from "vue";
export default {
  namespaced: true,
  state: {
    invoice: [],
    taxes: [],
    priceWithoutVat: 0,
    description: null,
    totalPieces: 0,
    discount: {
      type: 1,
      value: 0,
      discount_amount: 0,
      percent_value: 0,
      is_coupon: 0,
      coupon_id: 0,
      coupon: {},
    },
    totalVat: 0,
    totalDiscountVat: 0,
    totalPrice: 0,
    totalPriceWithVat: 0,
    priceWithDiscount: 0,
    discountForEachItem: 0,
    customer_type: 1,
    customer_data: {
      id: 0,
      name: "-",
    },
    error: null,
  },
  getters: {
    getInvoiceStore: (state) => {

      //  Invoice Calculations
      // state.invoice.forEach(item => {
      //     item.total_price_without_vat = item.price * item.quantity;
      //     item.total_vat = item.total_price_without_vat * (item.tax_percentage / 100);
      //     item.total_with_vat = item.total_price_without_vat  + item.total_vat;
      //     item.total_discount = state.discount.discount_amount ? (item.total_price_without_vat * (state.discount.percent_value/100)) : 0; // <=
      //     item.total_price_with_discount = item.total_price_without_vat -  item.total_discount;
      //     item.total_vat_after_discount =  item.total_price_with_discount * (item.tax_percentage / 100);
      //     item.total_price_after_discount_with_vat =   item.total_price_with_discount + item.total_vat_after_discount;
      //   });

      //   // Reduces
      //   let total_discount = state.invoice.reduce((oldValue, newValue) => {
      //         return Number(+oldValue) + Number(+newValue.total_discount);}, 0);
      //   let total_price_with_discount = state.invoice.reduce((oldValue, newValue) => {
      //         return Number(+oldValue) + Number(+newValue.total_price_with_discount);}, 0);
      //   let total_vat_after_discount = state.invoice.reduce((oldValue, newValue) => {
      //         return Number(+oldValue) + Number(+newValue.total_vat_after_discount);}, 0);
      //   let total_price_after_discount_with_vat = state.invoice.reduce((oldValue, newValue) => {
      //         return Number(+oldValue) + Number(+newValue.total_price_after_discount_with_vat);}, 0);



      let CALCULATION = Vue.prototype.$global.PRICE_CALCULATION(state.invoice, state.discount)
      state.invoice = CALCULATION.new_array
      return {
        discount: state.discount,
        description: state.description,
        total_price_with_discount: CALCULATION.total_price_with_discount, // without vat
        total_pieces: CALCULATION.quantity,
        total_discount: CALCULATION.total_discount,
        total_vat: CALCULATION.total_vat,
        total_vat_after_discount: CALCULATION.total_vat_after_discount,
        total_price_after_discount_with_vat: CALCULATION.total_price_after_discount_with_vat,
        invoice: CALCULATION.new_array
      }
    },
    totalPriceWithDiscount: (state) => {  // without vat
      if (state.discount.type == 1) {
        return Number(state.totalPrice) - state.discount.value;
      }
      if (state.discount.type == 2) {
        return (
          Number(state.totalPrice) -
          Number(state.totalPrice) * (state.discount.value / 100)
        );
      } else {
        return Number(state.totalPrice);
      }
    },
  },
  mutations: {
    SET_DISCOUNT(state, data) {
      // console.log("data data data", data);
      state.discount = data;
    },
    UPDATE_TAXES(state, data) {
      // console.log("Taxes", data);
      state.taxes = data;
    },
    UPDATE_INVOICE(state, invoice) {

      let CALCULATION = Vue.prototype.$global.PRICE_CALCULATION(invoice, state.discount)
      state.invoice = CALCULATION.new_array
      state.totalPrice = CALCULATION.price_without_vat;
      state.totalVat = CALCULATION.total_vat;
      state.totalPriceWithVat = CALCULATION.total_price_after_discount_with_vat;
      state.totalPieces = CALCULATION.quantity;

    },
    UPDATE_CUSTOMER(state, type) {
      state.customer_data = type;
    },
    UPDATE_ERROR(state, error) {
      state.error = error;
    },
    CLEAR_INVOICE(state) {
      state.invoice = [];
      state.description = null;
      state.totalPrice = 0;
      state.totalPieces = 0;
      state.totalVat = 0;
      state.priceWithoutVat = 0;
      state.priceWithDiscount = 0;
      state.totalPriceWithVat = 0;
      state.discount = {
        type: 1,
        value: 0,
        discount_amount: 0,
        percent_value: 0,
      };
      state.error = null;
    },
    NEW_INVOICE(state) {
      state.invoice = [];
      state.totalPrice = 0;
      state.description = null;
      state.totalPieces = 0;
      state.totalVat = 0;
      state.priceWithoutVat = 0;
      state.totalPriceWithVat = 0;
      state.priceWithDiscount = 0;
      // state.customer_data = {
      //   id: 0,
      //   name: "-",
      // };
      state.discount = {
        type: 1,
        value: 0,
        discount_amount: 0,
        percent_value: 0,
      };
      state.error = null;
    },
  },
  actions: {
    changeCustomer({ commit }, data) {
      commit("UPDATE_CUSTOMER", data);
      // commit("CLEAR_INVOICE");
    },
    setInvoice({ commit }, data) {
      data.forEach(item => {
        item.total_price_without_vat = item.price * item.quantity;
        item.total_vat =
          item.total_price_without_vat * (item.tax_percentage / 100);
        item.total_with_vat = item.total_price_without_vat + item.total_vat;
      });
      commit("UPDATE_INVOICE", data);
    },
    setTaxesArray({ commit }, data) {
      commit("UPDATE_TAXES", data);
    },
    setDescription({ state }, description) {
      state.description = description
    },
    addInvoiceAction({ commit, dispatch, state }, data) {
      if (state.invoice.some((item) => item.id == data.id) == true) {
        //exist
        const getIndex = state.invoice.findIndex((item) => item.id == data.id);
        let item = state.invoice[getIndex];
        item.quantity = item.quantity + 1;
        // item.total_price_without_vat = item.price * item.quantity;
        // item.total_vat =
        //   item.total_price_without_vat * (item.tax_percentage / 100);
        // item.total_with_vat = item.total_price_without_vat + item.total_vat;
        state.invoice[getIndex] = item;
        commit("UPDATE_INVOICE", state.invoice);
      } else {
        // not exist
        data.quantity = 1;
        // data.total_price_without_vat = data.price * data.quantity;
        // data.total_vat =
        //   data.total_price_without_vat * (data.tax_percentage / 100);
        // data.total_with_vat = data.total_price_without_vat + data.total_vat;
        commit("UPDATE_INVOICE", state.invoice.concat(data));
      }
      const discount = {
        type: state.discount.type,
        value: state.discount.value,
        discount_amount: 0,
        percent_value: 0,
        coupon: state.discount.coupon,
      }
      dispatch('setDiscount', discount)
    },

    setTax({ commit, state }, newData) {
      console.log(newData.id, newData.tax_percent_id);
      const getRowIndex = state.invoice.findIndex(
        (item) => item.id == newData.id
      );
      const getTaxIndex = state.taxes.findIndex(
        (item) => item.id == newData.tax_percent_id
      );
      let item = state.invoice[getRowIndex];
      item.tax_percent_id = newData.tax_percent_id;
      item.tax_percentage = state.taxes[getTaxIndex].tax_percentage;
      item.total_price_without_vat = item.price * item.quantity;
      item.total_vat =
        item.total_price_without_vat * (item.tax_percentage / 100);
      item.total_with_vat = item.total_price_without_vat + item.total_vat;
      state.invoice[getRowIndex] = item;
      console.log(item);
      commit("UPDATE_INVOICE", state.invoice);
    },
    increment({ commit, dispatch, state }, id) {
      const getIndex = state.invoice.findIndex((item) => item.id == id);
      let item = state.invoice[getIndex];
      item.quantity = item.quantity + 1;
      // item.total_price_without_vat = item.price * item.quantity;
      // item.total_vat =
      //   item.total_price_without_vat * (item.tax_percentage / 100);
      // item.total_with_vat = item.total_price_without_vat + item.total_vat;
      state.invoice[getIndex] = item;
      commit("UPDATE_INVOICE", state.invoice);
      const discount = {
        type: state.discount.type,
        value: state.discount.value,
        discount_amount: 0,
        percent_value: 0,
        coupon: state.discount.coupon,
      }
      dispatch('setDiscount', discount)
    },
    decrement({ commit, dispatch, state }, id) {
      const getIndex = state.invoice.findIndex((item) => item.id == id);
      if (state.invoice[getIndex].quantity >= 2) {
        let item = state.invoice[getIndex];
        item.quantity = item.quantity - 1;
        // item.total_price_without_vat = item.price * item.quantity;
        // item.total_vat = item.total_price_without_vat * (item.tax_percentage / 100);
        // item.total_with_vat = item.total_price_without_vat  + item.total_vat;
        state.invoice[getIndex] = item;
        commit("UPDATE_INVOICE", state.invoice);
      } else {
        state.invoice.splice(getIndex, 1);
        commit("UPDATE_INVOICE", state.invoice);
      }
      const discount = {
        type: state.discount.type,
        value: state.discount.value,
        discount_amount: 0,
        percent_value: 0,
        coupon: state.discount.coupon,
      }
      dispatch('setDiscount', discount)
    },
    clearInvoice({ commit }) {
      commit("CLEAR_INVOICE");
    },
    resetInvoice({ commit }) {
      commit("NEW_INVOICE");
    },
    setDiscount({ commit, state }, data) {
      let discount_amount = 0;
      let percent_value = 0;
      if (data.type == 1) {
        discount_amount = state.totalPrice - data.value;
        percent_value = (data.value / state.totalPrice) * 100;
      }
      if (data.type == 2) {
        discount_amount = state.totalPrice - (state.totalPrice * (data.value / 100));
        percent_value = data.value;
      }
      const discountCalc = {
        type: data.type,
        value: data.value,
        discount_amount: discount_amount,
        percent_value: percent_value,
        is_coupon: data.is_coupon,
        coupon_id: data.coupon_id,
        coupon: data.coupon,
      }
      commit("SET_DISCOUNT", discountCalc);
    },
    deleteItemAction({ state, commit }, index) {
      state.invoice.splice(index, 1);
      commit("UPDATE_INVOICE", state.invoice);
    },
  },
};
