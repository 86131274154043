<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="550">
      <template v-slot:activator="{ on, attrs }">

        <div v-bind="attrs">

          <v-btn rounded-md v-on="on" color="gray8" class="rounded-sm white--text mx-2" v-if="!customer_data.id"
            :height="40" min-width="100" depressed>
            <span>{{ $t('customer') | capitalize }}</span>
          </v-btn>

          <div v-if="customer_data.id" class="d-flex align-center">
            <div class="mx-2"  v-if="!isBooking">
              <v-tooltip  bottom color="secondary" open-delay="500" open-on-hover>
                <template v-bind="attrs" v-slot:activator="{ on, attrs }">
                  <v-btn x-small v-on="on" @click="dialog = true" :height="37" :width="37" depressed
                    class="gray5 rounded-lg" icon>
                    <v-icon size="25" color="white">mdi-account-tie</v-icon>
                  </v-btn>
                </template>
                <span class="caption">{{ $t('choose customer') | capitalize }}</span>
              </v-tooltip>
            </div>
            <div class="cursor_default">
              <v-tooltip bottom color="light" open-delay="500" open-on-hover>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" class="caption font-weight-bold d-flex justify-space-between">
                    <div class="mx-2">{{ $t('name') | capitalize }}:</div>
                    <div class="mx-2 gray7--text d-inline-block text-truncate"
                      :style="$store.state.sidebarIsOpen ? `max-width: ${$store.state.Settings.windowSize.y - 450}px;` : `max-width: ${$store.state.Settings.windowSize.y - 330}px;`">
                      {{ customer_data.name | capitalize }} </div>
                  </div>
                  <div v-bind="attrs" v-on="on" class="caption font-weight-bold d-flex justify-space-between">
                    <div class="mx-2">{{ $t('mobile') | capitalize }}:</div>
                    <div class="mx-2 gray7--text">{{ customer_data.mobile || customer_data.phone }}</div>
                  </div>
                </template>
                <span class="secondary--text caption">{{ customer_data.name | capitalize }}</span>
              </v-tooltip>

            </div>
          </div>

        </div>

      </template>



      <v-card relative class="py-3">
        <v-btn absolute class="" :style="$vuetify.rtl == true ? 'left: 0; top : 0' : 'right: 0; top : 0'" small fab
          color="grey lighten-1" text @click="dialog = false">
          <v-icon color="grey lighten-1">mdi-close</v-icon>
        </v-btn>
        <v-card-title class="text-h6 font-weight-bold">
          {{ viewForm ? $t('customer info') : $t('select customer') | capitalize }}
        </v-card-title>

        <!-- search -->
        <v-col cols="12" class="d-flex align-center">
          <!-- <v-text-field class="my-2" solo background-color="#f6f5f5" :placeholder="$i18n.t('search')" v-model="search"
            @keydown.enter="searchReturnSales" rounded-md dense autofocus hide-details type="text"
            @click="searchResult = [], viewForm = false"></v-text-field> -->

          <v-text-field background-color="#f6f5f5" v-model="search" class="my-2" @keydown.enter="searchReturnSales"
            @click:clear="searchResult = [], viewForm = false" outlined
            @click:append="searchResult = [], viewForm = false, searchReturnSales()"
            @click="searchResult = [], viewForm = false" append-icon="mdi-magnify" :placeholder="$i18n.t('search')"
            single-line clearable hide-details solo dense :height="37"></v-text-field>

          <!-- <v-btn @click="searchReturnSales" :disabled="loading" :loading="loading" class="my-2 mx-2 shadow"
            color="success">{{ $t('search') }}
          </v-btn> -->

          <v-btn @click="viewForm = true, search = ''" v-if="!viewForm" :disabled="viewForm" class="my-2 mx-2 shadow"
            color="primary">
            <span class="d-md-block d-none">{{ $t('add new') | capitalize }}</span>
          </v-btn>
        </v-col>

        <!-- Search result -->
        <v-col cols="12" sm="12" v-if="searchResult.length > 0 && !viewForm">
          <v-card class="backgroundW elevation-0">
            <v-simple-table :height="this.$store.state.Settings.windowSize.y - 300">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center">{{ $t('customer name') | capitalize }}</th>
                    <th class="text-center">{{ $t('mobile') | capitalize }}</th>
                    <th class="text-center"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row, index) in searchResult" :key="index">
                    <td>{{ row.name || '-' }}</td>
                    <td>{{ row.phone || '-' }}</td>
                    <td>
                      <v-btn color="success" @click="selectCustomer(row)" small>{{ $t('choose') }}</v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>

        <!-- Form -->
        <v-col cols="12" sm="12" v-if="viewForm">
          <v-card elevation="0" class=" pa-0">
            <v-form ref="form" v-model="valid">
              <v-row align="center" justify="end">
                <v-col cols="12" sm="12" class="py-0">
                  <v-alert type="primary" outlined dense v-if="no_customer_found">
                    {{ $t('no customer found, you can added new one') }}
                  </v-alert>
                </v-col>
                <v-col cols="12" sm="6" class="py-0">
                  <v-text-field solo background-color="#f6f5f5" outlined v-model="form.name"
                    :placeholder="$i18n.t('customer name') | capitalize" :rules="$store.state.Settings.requiredRule"
                    rounded-md dense type="text" />
                </v-col>
                <v-col cols="12" sm="6" class="py-0">
                  <v-text-field solo background-color="#f6f5f5" outlined rounded-0
                    :rules="$store.state.Settings.requiredRule" v-model="form.phone"
                    :placeholder="$i18n.t('mobile') | capitalize" rounded-md dense type="tel" />
                </v-col>
                <v-col cols="12" class="pb-0">
                  <v-textarea solo background-color="#f6f5f5" outlined rows="3" v-model="form.notes"
                    :placeholder="$i18n.t('notes') | capitalize" rounded-md dense></v-textarea>
                </v-col>
                <v-col cols="12" sm="auto">
                  <v-btn color="success" :min-width="120" :disabled="!valid" :loading="loading" @click="addCustomer"
                    class="shadow">{{ $t('save data') }}</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card>
        </v-col>

        <v-col cols="auto" v-if="loading">
          <v-progress-circular :size="70" :width="5" style="margin: auto; " class="d-block" color="primary"
            indeterminate></v-progress-circular>
          <p class="text-center">{{ $t('loading data') | capitalize }}</p>
        </v-col>

      </v-card>
    </v-dialog>
  </v-row>
</template>



<style scoped lang="scss">

</style>



<script>
import { createNamespacedHelpers } from "vuex";
// import GenericInput from "../GenericInput.vue";
const { mapState, mapActions } = createNamespacedHelpers("InvoiceStore");
export default {
  name: "CustomerModal",
  props: {
    backValueMethod: { type: Function },
    isBooking: { default: false },
  },
  computed: {
    ...mapState(["customer_type", "customer_data"]),
  },
  watch: {
    customer_type() {
      this.searchResult = [];
      this.$refs.form.reset();
    },
    dialog() {
      this.viewForm = false;
    },
    '$store.state.Settings.key_clicked'() {
      switch (this.$store.state.Settings.key_clicked) {
        case 'F4':
          this.dialog = !this.dialog
          break;
        case 'Escape':
          this.dialog = false
          break;
        default:
          break;
      }
    },
  },
  data() {
    return {
      loading: false,
      no_customer_found: false,
      dialog: false,
      valid: false,
      search: "",
      searchResult: [],
      viewForm: false,
      form: {
        name: null,
        phone: null,
        notes: null,
      },
    };
  },
  mounted() {

  },
  methods: {
    ...mapActions(["changeCustomer"]),
    selectCustomer(data) {
      console.log(data);
      this.dialog = false;
      this.viewForm = false;
      this.changeCustomer(data).finally(() => { })
      this.searchResult = [];

    },
    searchReturnSales() {
      this.loading = true;
      this.$api.GET_METHOD(`search_customer?word=${this.search || ''}`).then((response) => {
        this.loading = false;
        // console.log(response);
        if (response.check) {
          this.searchResult = response.data || [];
          var pattern = /[a-zA-Z]/;
          if (response.data.length == 0) {
            this.no_customer_found = true;
            if (!pattern.test(this.search)) {
              this.form.phone = this.search;
              this.form.name = null;
              this.viewForm = true
            }
            if (pattern.test(this.search)) {
              this.form.name = this.search;
              this.form.phone = null;
              this.viewForm = true
            }
          }
        }
      });
    },
    addCustomer() {
      this.loading = true;
      this.form.type = this.customer_type;
      this.$api.POST_METHOD("add_customer", this.form).then((response) => {
        this.loading = false;
        if (response.check) {
          // this.backValueMethod(response.data[0]);
          this.changeCustomer(response.data)
          this.dialog = false;
          this.viewForm = false;
          this.$refs.form.reset();
        }
      });
    }
  },
  components: {
    //  GenericInput
  }
};
</script>
