<template>
  <section>
    <v-container fluid>
      <v-col cols="12" class="pt-0 mb-3">
        <v-row justify="space-between" align="center">
          <v-col cols="7" class="px-0">
            <v-row align="center">
              <v-col cols="auto" class="d-none d-lg-block">
                <div class="font-weight-bold"> {{ $t('requests list') }}</div>
              </v-col>
              <!-- <GenericInput type="select" :keydownEnter="getData" paddingX="px-1"  backgroundColor="white" :clearable="false" classes="rounded-pill" dense :solo="true" :lookups="types" selected_label="name"
              selected_prop="id" :value="view_type" @input="view_type = $event" label="choose report"
              :isLoading="pageData.isLoading" :cols="[12, 12, 2]" /> -->
              <GenericInput type="date" :keydownEnter="getData" paddingX="px-1" backgroundColor="white"
                prefix="from date" dense :value="from_date" :maxDate="to_date || today" @input="from_date = $event"
                label="" :required="false" :hide-details="true" :isLoading="pageData.isLoading" :cols="[6, 6, 4]">
              </GenericInput>
              <GenericInput type="date" :keydownEnter="getData" paddingX="px-1" backgroundColor="white" prefix="to date"
                dense :value="to_date" :minDate="from_date" :maxDate="today" @input="to_date = $event" label=""
                :required="false" :hide-details="true" :isLoading="pageData.isLoading" :cols="[6, 6, 4]">
              </GenericInput>

            </v-row>
          </v-col>
          <v-col cols="5" class="px-0">
            <v-row justify="end" align="center">
              <v-col cols="6" class="px-1">
                <v-text-field outlined v-model="pageData.search" :loading="pageData.isLoading"
                  @keydown.enter="(e) => getSearch(e.target.value)" @click:clear="(e) => getSearch(e.target.value)"
                  @click:append="getSearch(pageData.search)" append-icon="mdi-magnify" :placeholder="
  pageData.isTrashed == 0 ? $i18n.t('Search in deleted') : $i18n.t('search')
" single-line clearable hide-details solo dense :height="37"></v-text-field>
              </v-col>
              <v-col cols="auto" class="px-1">
                <v-btn block depressed :loading="pageData.isLoading" @click="getSearch(pageData.search)" :height="37"
                  :width="100" color="blue4" outlined class="rounded-lg">
                  {{ $t("search") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-row align="center" justify="center">
        <v-col cols="12" class="py-0">
          <v-card class="elevation-0 pa-0 rounded-md" color="transparent">
            <DynamicTable :tableHeight="$store.state.Settings.windowSize.y - 200" :viewTitle="false"
              :isLoading="pageData.isLoading" :data="pageData.rows" :header="pageData.tableHeader"
              :option="pageData.options" tableTitle="requests list" :pageData="pageData">
              <template v-slot:td="{ row, header }">
                <div v-if="header.key == 'actions'">
                  <v-btn @click="print(row)" x-small icon fab><v-icon>mdi-printer</v-icon></v-btn>
                  <v-btn @click="showInvoice(row)" x-small icon fab><v-icon>mdi-eye</v-icon></v-btn>
                </div>
              </template>
            </DynamicTable>
          </v-card>
        </v-col>

        <v-col cols="12" sm="12" v-if="!pageData.isLoading && pageData.rows.length > 0">
          <Pagination :pagination="pageData.pagination" :limit="pageData.rowsNumber" :backValueMethod="changePage"
            :isLoading="pageData.isLoading"></Pagination>
        </v-col>

      </v-row>

    </v-container>
  </section>
</template>

<script>

import DynamicTable from '@/components/DynamicTable.vue';
import GenericInput from '@/components/GenericInput.vue';
import Pagination from '@/components/Pagination.vue';

export default {
  name: "RequestEntity",
  computed: {

  },
  components: {
    DynamicTable,
    GenericInput,
    Pagination
  },

  data: () => ({
    pageData: {
      screen_code: "06-008",
      url: '/requests-list',
      controlRoute: null,
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
      controlDialog: false,
      controlID: null,
      queryParam: '?'
    },
    from_date: null,
    to_date: null,
    today: null,
    view_type: 1,
    types: [],
  }),
  watch: {
    $route() {
      this.pageData.queryParam = '?';
      this.queryParams();
      this.pageMainData();
      this.getData();
    },
    '$store.state.Settings.key_clicked'() {
      switch (this.$store.state.Settings.key_clicked) {
        case 'F5':
          this.getData();
          break;
        default:
          break;
      }
    },
  },
  mounted() {
    this.queryParams();
    this.pageMainData();
    this.today = this.$global.GetCurrentDate();
    this.from_date = this.$global.GetFirstDayOfMonth();
    this.to_date = this.$global.GetCurrentDate();
    this.getData();
  },
  methods: {
    queryParams() {
      this.pageData.isTrashed = Number(this.$store.state.activeScreen.queryParams.active);
      this.pageData.page = Number(this.$store.state.activeScreen.queryParams.page) || 1;
      this.pageData.rowsNumber = Number(this.$store.state.activeScreen.queryParams.rows) || 10;
      this.pageData.search = this.$store.state.activeScreen.queryParams.word || '';
      this.pageData.queryParam = this.$store.state.activeScreen.requestParam || '';
    },
    pageMainData() {
      this.pageData.tableHeader = [
        { text: "request", key: "m_id", type: 'text', classes: "" },
        { text: "date", key: "sales_invoice_date", type: 'date', classes: "" },
        { text: "customer", key: "customer_name", type: 'text', classes: "" },
        { text: "mobile", key: "customer_phone", type: 'text', classes: "" },
        { text: "items count", key: "items_count", type: 'text', classes: "" },
        { text: "payment type", key: "payment_type_title", type: 'text', classes: "" },
        { text: "cost", key: "total_with_vat", type: 'float', classes: "" },
        { text: "actions", key: "actions", type: 'slot', classes: ""  , hideInPrint : true},
      ];
      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: this.$global.CheckAction(this.pageData.screen_code, 4),
        edit: this.$global.CheckAction(this.pageData.screen_code, 3),
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: this.$global.CheckAction(this.pageData.screen_code, 1),
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: false,
        handleClickRow: false,
      };
      this.types = [{ name: this.$i18n.t('view all'), id: 1 }, { name: this.$i18n.t('notice debtor'), id: 2 }, { name: this.$i18n.t('notice creditor'), id: 3 }]
    },
    getSearch(word) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${this.pageData.rowsNumber}&page=${this.pageData.page}&word=${word || ''}&from_date=${this.from_date || ''}&to_date=${this.to_date || ''}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    getData() {
      if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
        this.pageData.isLoading = true;
        this.$api
          .GET_METHOD(`get_sales_invoices${this.pageData.queryParam}&from_date=${this.from_date}&to_date=${this.to_date}`)
          .then((response) => {
            this.pageData.isLoading = false;
            if (response.check) {
              this.pageData.entityName =
                this.pageData.isTrashed == 0
                  ? this.$i18n.t("deleted from") + " " + this.$store.state.activeScreen.sub_title
                  : this.$store.state.activeScreen.sub_title;
              this.pageData.rows = response.data.items.data;
              this.pageData.page = response.data.items.current_page;
              this.pageData.pagination = {
                page: response.data.items.current_page,
                totalPages: response.data.items.last_page,
                per_page: response.data.items.per_page,
                totalRows: response.data.items.total,
              };

            }
          });
      } else {
        this.$router.push("/");
        this.$global.ShowAlert('info', this.$i18n.t('no permission'), false, this.$i18n.t('check permission with admin'))
      }
    },
    showInvoice(row) {
      this.$router.push({
        path: `/requests-control/${row.m_id}`,
        query: { m_id: row.m_id, prevScreenQueryParam: this.pageData.queryParam, prevRoute: '/requests-list' },
      });
    },
    changePage(page, limit) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${limit}&page=${page}&word=${this.pageData.search || ''}&from_date=${this.from_date || ''}&to_date=${this.to_date || ''}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    print(row) {
      // const src = `src="${this.$api.serverUrl}export_sales_invoice/${row.m_id}?type=image&clientID=${this.$store.state.clientID}"`;
      // this.$api.PrintInvoice(src,row. m_id)
      const src = `${this.$api.serverUrl}print_sales_invoice/${row.m_id}?type=print&clientID=${this.$store.state.clientID}`;
      this.$api.PrintInvoiceURL(src, row.m_id);
    },
  },

};
</script>
