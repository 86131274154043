<template>
  <v-container fluid class="backgroundW" id="appBar">
    <v-row justify="space-between" align="center">
      <v-col cols="auto" class="py-5">
        <v-row align="center" justify="start">
          <v-col cols="4" md="auto" sm="auto" class="py-0">
            <v-btn small fab elevation="0" @click="toggleSidebar">
              <img src="@/assets/img/pos_svg/menu.svg" class="mx-2" height="20" alt="Qawaem-POS">
            </v-btn>
          </v-col>
          <v-col cols="auto" class="py-0">
            <router-link to="/">
              <!-- <AnimatedLogo height="60px" /> -->
              <img src="@/assets/logo.png" style="max-height: 40px;" alt="Qawaem-POS" />
            </router-link>
          </v-col>




          <!-- <v-col cols="12" md="auto" class="d-none d-md-block" sm="auto">
            <v-btn small fab elevation="0" v-fullscreen.teleport="options">
              <v-icon v-if="!fullscreen">mdi-fullscreen</v-icon>
              <v-icon v-if="fullscreen">mdi-fullscreen-exit</v-icon>
            </v-btn>
          </v-col> -->

        </v-row>
      </v-col>
      <v-col cols="auto" class="py-2">
        <v-row align="center">
          <v-col cols="12" sm="auto" class="d-none d-md-block py-0">
            <v-btn v-on:click="changeLanguage" small fab class="bg-white d-block ma-auto" elevation="0">
              <img src="../assets/img/svg/USA.svg" height="25" v-if="$vuetify.rtl == true" alt="Qawaem-POS" />
              <img src="../assets/img/svg/KSA.svg" height="25" v-if="$vuetify.rtl == false" alt="Qawaem-POS" />
            </v-btn>
          </v-col>
          <v-col cols="auto" class="d-none d-md-block py-0" v-if="$global.CheckAction('10-004',1)">
            <v-badge :color="$store.state.bookingCount ? 'primary' : 'transparent'"
              :content="$store.state.bookingCount" overlap>
              <div class="d-flex light rounded-xl px-5 align-center cursor_pointer" style="height: 44px;"
                @click="barberBookingDialog = true">
                <img src="@/assets/img/pos_svg/scissors.svg" class="mx-2 d-none d-sm-block" height="23" alt="Qawaem-POS">
                <div class="text-start mt-n1">
                  <span class="font-weight-bold caption">{{ $t("barber booking") | capitalize }}</span>
                </div>
              </div>
            </v-badge>
          </v-col>
          <v-col cols="auto" class="d-none d-md-block py-0">
            <div class="d-flex light rounded-xl px-5 align-center">
              <img src="@/assets/img/pos_svg/branch.svg" class="mx-2 d-none d-sm-block" height="23" alt="Qawaem-POS">
              <div class="text-start">
                <div class="body-2 gray8--text">{{ $t("branch") }}</div>
                <span class="font-weight-bold caption">{{ activeBranch || $t("not available") }}</span>
              </div>
            </div>
          </v-col>

          <v-col cols="auto" class="d-none d-md-block py-0">
            <div class="d-flex light rounded-xl px-5 align-center cursor_pointer" @click="$router.push('/close-sift')">
              <img src="@/assets/img/pos_svg/endSheeft.svg" class="mx-2 d-none d-sm-block" height="35" alt="Qawaem-POS">
              <div class="text-start">
                <div class="body-2  gray8--text">{{ '2022-08-25' || $t("not available") }}</div>
                <span class="font-weight-bold caption">{{ $t("close shift") }}</span>
              </div>
            </div>
          </v-col>

          <v-col cols="auto" class="d-none d-md-block py-0">
            <div class="d-flex light rounded-xl px-5 align-center cursor_pointer" style="height: 44px;"
              @click="$api.LOGOUT(true)">
              <img src="@/assets/img/pos_svg/logout.svg" class="mx-2 d-none d-sm-block" height="23" alt="Qawaem-POS">
              <div class="text-start mt-n1">
                <span class="font-weight-bold caption">{{ $t("logout") | capitalize }}</span>
              </div>
            </div>
          </v-col>
          <v-col cols="auto" class="d-md-none py-0">
            <UserMenu />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <LogoutConfirmation :dialog="logoutDialog" :backValueMethod="logout"></LogoutConfirmation>
    <BarberBookingModal :dialog="barberBookingDialog" :returnCloseDialog="() => barberBookingDialog = false" />
  </v-container>
</template>




<script>

import UserMenu from "./menus/UserMenu.vue";
import BarberBookingModal from "./modals/BarberBookingModal.vue";
import LogoutConfirmation from "./modals/LogoutConfirmation.vue";
// import AnimatedLogo from "./AnimatedLogo.vue";

export default {
  name: "AppBar",
  computed: {
    options() {
      return {
        callback: (isFullscreen) => {
          this.fullscreen = isFullscreen
        },
        target: '.directive-fullscreen-wrapper',
        pageOnly: this.pageOnly,
        teleport: this.teleport
      }
    }
  },
  data: () => ({
    logoutDialog: false,
    barberBookingDialog: false,
    fullscreen: false,
    activeBranch: null,
  }),

  components: {
    LogoutConfirmation,
    BarberBookingModal,
    UserMenu
  },
  mounted() {
    this.getActiveBranch();
  },
  watch: {
    '$store.state.userData'() {
      this.getActiveBranch();
    }
  },

  methods: {
    getActiveBranch() {
      this.$store.state.branches.forEach((branch) => {
        if (branch.id == this.$store.state.current_branch) {
          this.activeBranch = branch.name;
        }
      });
    },
    toggleSidebar() {
      this.$store.state.sidebarIsOpen = !this.$store.state.sidebarIsOpen;
    },
    darkTheme() {
      this.$store.dispatch("Settings/darkTheme");
    },
    logout(status) {
      if (status == true) {
        this.$api.LOGOUT(true)
      }
      this.logoutDialog = false
    },
    changeLanguage() {
      this.$store.dispatch("Settings/changeLanguage");
    },
  },
};
</script>
<style lang="scss" scoped>
#appBar {
  position: fixed;
  height: 60px;
  z-index: 55;
  width: 100vw !important;
  left: 0 !important;
  right: 0 !important;
  //display: flex;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
}

.body-2 {
  @media screen and (max-width: 1024px) {
    font-size: 11px !important;
  }
}

.caption {
  @media screen and (max-width: 1024px) {
    font-size: 10px !important;
  }
}</style>