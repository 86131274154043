<template>
  <section>
    <v-container fluid>

      <v-col cols="12" md="6" lg="8" class="pt-0">
        <v-row justify="space-between">
          <div class=" d-flex align-center  mx-2">
            <img src="@/assets/img/pos_svg/back arrow.svg" @click="pageBack"
              class="light rounded-pill cursor_pointer pa-2" height="40" alt="qawaem" />
            <div class="text-h6">{{ $t('request') }} #{{master.m_id || 0}}</div>
          </div>
          <v-col cols="auto">
            <v-btn color="success" @click="print" :disabled="pageData.isLoading" :width="120" class="white--text"
              depressed>
              {{$t('print invoice')}}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>


      <v-col cols="12" v-if="!pageData.isLoading">
        <v-row>
          <v-col cols="12" md="6" lg="8" class="">

            <v-row align="center" justify="space-between">

              <v-col cols="12" class="py-0">
                <v-card class="shadow-none pa-5">
                  <v-row>
                    <!--  -->
                    <v-col cols="12" md="6" class="py-1">
                      <div class="subtitle-2">
                        {{$t('request date')}} : <span class="gray8--text mx-2">
                          {{$t(master.sales_invoice_date || '-')}}
                        </span>
                      </div>
                    </v-col>
                    <v-col cols="12" md="6" class="py-1">
                      <div class="subtitle-2">
                        {{$t('customer name')}} : <span class="gray8--text mx-2">
                          {{$t(master.customer_name || '-')}}
                        </span>
                      </div>
                    </v-col>
                    <!--  -->
                    <v-col cols="12" md="6" class="py-1">
                      <div class="subtitle-2">
                        {{$t('request status')}} : <span class="success--text mx-2">
                          {{$t(master.sales_invoice_status || '-')}}
                        </span>
                      </div>
                    </v-col>
                    <v-col cols="12" md="6" class="py-1">
                      <div class="subtitle-2">
                        {{$t('customer name')}} : <span class="gray8--text mx-2">
                          {{$t(master.customer_phone || '-')}}
                        </span>
                      </div>
                    </v-col>
                    <!--  -->
                    <v-col cols="12" md="6" class="py-1">
                      <div class="subtitle-2">
                        {{$t('payment method')}} : <span class="success--text mx-2">
                          {{$t(master.payment_type_title || '-')}}
                        </span>
                      </div>
                    </v-col>
                    <v-col cols="12" md="6" class="py-1">
                      <div class="subtitle-2">
                        {{$t('employee name')}} : <span class="success--text mx-2">
                          {{$t(master.sales_agent_name || '-')}}
                        </span>
                      </div>
                    </v-col>
                    <v-col cols="12" class="py-1">
                      <v-divider />
                    </v-col>


                    <!-- Details -->
                    <v-col cols="12" class="py-1" v-for="(row, index) in details" :key="index">
                      <v-row justify="space-between" align="center">
                        <v-col cols="12" sm="6" md="6">
                          <div class="d-flex align-center text-center">
                            <v-avatar size="70" class="rounded-0 mx-2" style="border: 1px solid #eee"
                              v-lazy-container="{ selector: 'img'  }">
                              <img :data-src="$store.state.endpointURL + row.item_image" class="pa-2"
                                :alt="row.item_name" />
                            </v-avatar>
                            <div class="subtitle-1"> {{ row.item_name }} <v-chip small v-if="row.detail_type == 2">
                                {{$t('notice')}}</v-chip>
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                          <div class="light d-block ma-auto px-6 font-weight-bold py-1 subtitle-2"
                            style="width:fit-content"> {{ row.quantity }} {{$t('pcs')}} </div>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                          <div class="d-block mi-start-auto px-6 font-weight-bold  success--text subtitle-2"
                            style="width:fit-content"> {{ row.total_with_vat |float}} {{$t('sar')}} </div>
                        </v-col>
                      </v-row>
                    </v-col>

                  </v-row>
                </v-card>
              </v-col>
            </v-row>

          </v-col>
          <!-- summery -->
          <v-col cols="12" md="6" lg="4" class="py-0">
            <v-card class="shadow-none pa-5">
              <v-row align="center">
                <!--  -->
                <v-col cols="12" md="8" class="py-1">
                  <div class="subtitle-2">
                    {{$t('total price without vat')}} <small>({{master.items_count || 0}})</small>
                  </div>
                </v-col>
                <v-col cols="12" md="4" class="py-1 text-end">
                  <div class="gray5--text subtitle-2">
                    {{master.total_without_vat || 0 | float}}
                  </div>
                </v-col>
                <!--  -->
                <v-col cols="12" md="8" class="py-1">
                  <div class="subtitle-2">
                    {{$t('discount')}}
                  </div>
                </v-col>
                <v-col cols="12" md="4" class="py-1 text-end">
                  <div class="gray5--text subtitle-2">
                    {{master.discount | float}} {{master.discount_type==2 ? '%' : ''}}
                  </div>
                </v-col>
                <!--  -->
                <v-col cols="12" md="8" class="py-1">
                  <div class="subtitle-2">
                    {{$t('vat')}}
                  </div>
                </v-col>
                <v-col cols="12" md="4" class="py-1 text-end">
                  <div class="gray5--text subtitle-2">
                    {{master.total_vat || 0 | float}}
                  </div>
                </v-col>
                <!--  -->
                <v-col cols="12" class="py-1">
                  <v-divider />
                </v-col>
                <!--  -->
                <v-col cols="12" md="8" class="py-1">
                  <div class="subtitle-2">
                    {{$t('total with vat after discount')}}
                  </div>
                </v-col>
                <v-col cols="12" md="4" class="py-1 text-end">
                  <div class="gray5--text subtitle-2">
                    {{master.total_with_vat || 0 | float}}
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" v-if="pageData.isLoading">
        <AnimatedLoading classes="pt-2 my-15 pa-5" :isLoading="pageData.isLoading" />
      </v-col>

    </v-container>
  </section>
</template>

<script>
import AnimatedLoading from '@/components/AnimatedLoading.vue';



export default {
  name: "RequestsControl",
  computed: {

  },
  components: {
    AnimatedLoading
  },

  data: () => ({
    // 
    master: {},
    details: [],
    // page data
    pageData: {
      screen_code: "01-001",
      url: null,
      controlRoute: null,
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      isTrashed: 1,
      rowsNumber: 10,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
      controlDialog: false,
      controlID: null,
      queryParam: '?'
    },
  }),
  watch: {
    $route() {
      this.pageData.queryParam = '?';
      this.queryParams();
      this.pageMainData();
      this.getData();
    }
  },
  mounted() {
    this.queryParams();
    this.pageMainData();
    this.getData();
  },
  methods: {
    pageBack() {
      this.$router.push((this.$route.query.prevRoute || '/requests-list') + (this.$route.query.prevScreenQueryParam || ''))
    },
    queryParams() {

    },
    pageMainData() {

    },

    getData() {
      if (this.$route.params.id) {
        this.pageData.isLoading = true;
        this.$api.GET_METHOD(`get_sales_invoice/${this.$route.params.id}`).then((response) => {
          this.pageData.isLoading = false;
          if (response.check) {
            console.log("response", response);
            this.master = response.data.master;
            this.details = response.data.details;
          }
        })
      }

    },

    print() {
      const src = `src="${this.$api.serverUrl}export_sales_invoice/${this.master.m_id}?type=image&clientID=${this.$store.state.clientID}"`;
      this.$api.PrintInvoice(src, this.master.m_id)
    },
  },
};
</script>
