<template>
  <section>
    <v-card class="shadow-none rounded-sm overflow-auto" :height="$store.state.Settings.windowSize.y - 220">


      <v-col cols="12" class="mt-5 rounded-sm pa-5" sm="12">
        <v-row align="center" justify="space-between">
          <v-col cols="12" md="auto" class="pa-0">

            <div class="subtitle-1" v-if="active_step == 1 && activeCategoryID == 0">{{ $t('main category') |
            capitalize }}</div>
            <div class="subtitle-1 d-flex align-center cursor_pointer"
              @click="active_step = 1; activeCategoryID = activeCategory.parent_id ;activeCategoriesArray = categories"
              v-if="active_step ==  1 && activeCategoryID != 0">
              <div class=" d-flex align-center  mx-2" v-if="activeCategoryID != 0">
                <img src="@/assets/img/pos_svg/back arrow.svg" class="light rounded-pill pa-2" height="40"
                  alt="qawaem" />
              </div>
              <div>
                {{ $t('sub category') | capitalize }}
              </div>
            </div>

            <div class="subtitle-1 d-flex align-center cursor_pointer"
              @click="active_step = 1; activeCategoryID = activeCategory.parent_id" v-if="active_step == 2">
              <div class=" d-flex align-center  mx-2">
                <img src="@/assets/img/pos_svg/back arrow.svg" class="light rounded-pill pa-2" height="40"
                  alt="qawaem" />
              </div>
              <div>
                {{ $t('select item') | capitalize }}
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="6" class="pa-0">
            <v-text-field background-color="#e9e9e9" v-model="search" @keydown.enter="(e) => getSearch(e.target.value)"
              @click:clear="(e) => getSearch(e.target.value)" @click:append="getSearch(search)"
              append-icon="mdi-magnify" :placeholder="$i18n.t('item name or scan items') | capitalize" single-line
              clearable hide-details solo dense :height="37">
            </v-text-field>
          </v-col>
        </v-row>
      </v-col>

      <!-- Categories -->
      <v-col cols="12" class="pt-2 pa-5" v-if="active_step == 1 && !isLoading">
        <CategoriesBar :categories="activeCategoriesArray" :activeCategory="activeCategoryID"
          :backValueMethod="setActiveCategory" :noticeInvoice="true" />
      </v-col>
      <!-- Items -->
      <v-col cols="12" class="pt-2 pa-5" v-if="active_step == 2 && !isLoading">
        <CategoryItems :items="items" :activeCategory="activeCategoryID" :backItemMethod="backItemMethod"
          :backValueMethod="setActiveItem" :noticeInvoice="true" />

      </v-col>
      <v-col cols="12" v-if="isLoading">
        <AnimatedLoading classes="pt-2 my-15 pa-5" :isLoading="isLoading" />
      </v-col>
    </v-card>
    <v-col cols="12" class="d-flex pa-0 align-center justify-center" v-if="!isLoading && active_step == 2">
      <!-- prev -->
      <v-btn depressed :disabled="(itemPage == 1)" :isLoading="isLoading" @click="changeItemPage(itemPage - 1)"
        class="mx-5 success white--text"
        :class="!$vuetify.rtl ? 'rounded-bl-xl rounded-tl-xl': 'rounded-br-xl rounded-tr-xl'">
        <v-icon v-if="$vuetify.rtl">mdi-arrow-right-drop-circle</v-icon>
        <v-icon v-if="!$vuetify.rtl">mdi-arrow-left-drop-circle</v-icon>
      </v-btn>
      <!-- next -->
      <v-btn depressed @click="changeItemPage(itemPage + 1)" :isLoading="isLoading" :disabled="(itemMaxPage == itemPage)"
        class="mx-5 success white--text"
        :class="!$vuetify.rtl ? 'rounded-br-xl rounded-tr-xl': 'rounded-bl-xl rounded-tl-xl'">
        <v-icon v-if="!$vuetify.rtl">mdi-arrow-right-drop-circle</v-icon>
        <v-icon v-if="$vuetify.rtl">mdi-arrow-left-drop-circle</v-icon>
      </v-btn>
    </v-col>
  </section>
</template>

<script>
import CategoriesBar from './CategoriesBar.vue';
import CategoryItems from './CategoryItems.vue';
import AnimatedLoading from './AnimatedLoading.vue';



export default {
  name: "ItemsControl",
  computed: {

  },
  props: {
    backValueMethod: { type: Function },
    backItemMethod: { type: Function },
    categories: { default: [] },
    sub_categories: { default: [] },
    allCategoriesArray: { default: [] },
    activeCategoriesArray: { default: [] },
  },
  components: {
    CategoriesBar,
    CategoryItems,
    AnimatedLoading
  },
  created: function () {

  },
  destroyed: function () {

  },
  data: () => ({
    // Keys
    isLoading: false,
    activeCategoryID: 0,
    activeCategory: null,
    activeItem: null,
    search: '',
    active_step: 1,
    itemPage: 1,
    itemMaxPage: 1,
    // lockups
    items: [],

  }),
  watch: {

  },
  mounted() {

  },
  methods: {
    setActiveCategory(category) {
      if (category) {
        const getCategoryIndex = this.allCategoriesArray.findIndex((cat) => cat.id == category);
        let selectedCat = this.allCategoriesArray[getCategoryIndex]
        console.log('selected Category', selectedCat);
        this.itemPage = 0;
        this.itemMaxPage = 0;
        if (selectedCat.category_type == 0) {
          this.activeCategoryID = category;
          this.activeCategory = selectedCat;
          this.active_step = 2;
          this.getItemsByCategory()
        } else {
          console.log('sub_categories', this.sub_categories);
          this.activeCategoriesArray = this.$global.FilterArrayOfObject(this.allCategoriesArray, 'parent_id', selectedCat.id);
          console.log('activeCategoriesArray', this.activeCategoriesArray);
          this.activeCategoryID = selectedCat.id;
          this.activeCategory = selectedCat;
          this.active_step = 1;
        }
      }

    },
    getSearch(word) {
      if (word) {
        this.active_step = 2;
        this.isLoading = true;
        this.itemPage = 0;
        this.itemMaxPage = 0;
        this.$api.GET_METHOD(`search_item?word=${word}`).then((response) => {
          this.isLoading = false;
          this.items = []
          if (response.check) {
            if (response.data.items.length > 0) {
              response.data.items.forEach(item => {
                switch (item.default_report_unit_type) {
                  case 1:
                    item.unit_id = item.small_unit_id;
                    break;
                  case 2:
                    item.unit_id = item.mid_unit_id;
                    break;
                  case 3:
                    item.unit_id = item.big_unit_id;
                    break;

                  default:
                    item.unit_id = 0
                    break;
                }
                var newItem = {
                  id: item.id,
                  item_id: item.id,
                  name: item.name,
                  item_name: item.name,
                  item_image: item.image,
                  category_id: item.category_id,
                  image: item.image,
                  category_name: item.category_name,
                  price: item.sale_price,
                  unit_id: item.unit_id,
                  quantity: 1,
                  tax_percent_id: item.tax_id,
                  tax_percentage: item.tax_percent,
                };
                this.items.push(newItem)
              });
            } else {
              this.$global.ShowAlert('info', this.$i18n.t('no search results'), false, this.$i18n.t(''))
            }
          }
        });
      } else {
        this.active_step = 1;
      }
    },
    getItemsByCategory() {
      this.isLoading = true;
      this.$api.GET_METHOD(`get_items_by_category?category_id=${this.activeCategoryID}&rows=20&page=${this.itemPage}`).then((response) => {
        this.isLoading = false;
        this.items = [];
        if (response.check) {
          this.itemPage = response.data.data.current_page;
          this.itemMaxPage = response.data.data.last_page;
          response.data.data.data.forEach(item => {
            switch (item.default_report_unit_type) {
              case 1:
                item.unit_id = item.small_unit_id;
                break;
              case 2:
                item.unit_id = item.mid_unit_id;
                break;
              case 3:
                item.unit_id = item.big_unit_id;
                break;

              default:
                item.unit_id = 0
                break;
            }
            var newItem = {
              id: item.id,
              item_id: item.id,
              name: item.name,
              item_name: item.name,
              item_image: item.image,
              category_id: item.category_id,
              image: item.image,
              category_name: item.category_name,
              price: item.sale_price,
              unit_id: item.unit_id,
              quantity: 1,
              tax_percent_id: item.tax_id,
              tax_percentage: item.tax_percent,

            };
            this.items.push(newItem)
          });

        }
      });
    },
    setActiveItem(item) {
      this.activeItem = item;
    },
    changeItemPage(page) {
      if (page > 0) {
        this.itemPage = page;
        this.getItemsByCategory()
      }
    },
  },
};
</script>


<style lang="scss" scoped>

</style>